import React, { useEffect, useState } from 'react';
import { Image } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'semantic-ui-react';

import Points from '../components/Points';
import BackButton from '../components/BackButton';
import Advertisement from './Arcade/Advertisement';
import { ReactComponent as BoosterIcon } from '../images/game-icons/booster.svg';
import SprintIcon from '../images/game-icons/sprint.png';
import RiskItIcon from '../images/game-icons/riskit.png';
import QODIcon from '../images/game-icons/qod.png';
import { optIn } from '../modules/gamePlay/actions';

function Arcade({ history }) {
  const dispatch = useDispatch();
  const { current_points, riskit_keys } = useSelector(
    state => state.player.info
  );
  const { opt_in } = useSelector(state => state.gamePlay.campaign);
  const { riskItBaskets } = useSelector(state => state.gamePlay.settings);
  const { cover } = useSelector(state => state.ui);
  const sprintCount = useSelector(
    state => state.player.info.unanswered_trigger_count
  );
  const [showOptIn, setShowOptIn] = useState(false);
  const [showOptinMessage, setShowOptinMessage] = useState(false);

  const handleScreenChange = location => {
    history.replace('/arcade/' + location);
  };

  const handleButtonOnClick = () => {
    history.replace('/');
  };

  useEffect(() => {
    if (opt_in === 0 && !cover) {
      setShowOptIn(true);
    } else {
      setShowOptIn(false);
    }
  }, [opt_in, cover]);

  return [
    <div key={0} className="container">
      <div className="row page-control">
        <div className="col-auto">
          <BackButton onClick={handleButtonOnClick} />
        </div>
        <div className="col">
          <Points position="right" points={current_points} />
        </div>
      </div>

      <div className="row game-select">
        <div className="col">
          <Advertisement />
        </div>
      </div>

      <div className="row game-select">
        <div className="col">
          <div
            className="game-item"
            onClick={() => handleScreenChange('sprint')}
          >
            <div className="object">
              <Image fluid src={SprintIcon} />
            </div>
            <div className="content">
              <h3>
                Sprint (<span className="highlight">{sprintCount}</span>)
              </h3>
              Answer a series of 10 question for cool points to rank up.
            </div>
            <div className="extra">
              <span className="play">play</span>
            </div>
          </div>
        </div>

        <div className="col-4">
          <div
            className="game-item"
            onClick={() => handleScreenChange('boosters')}
          >
            <div className="object svg center">
              <div className="inner">
                <BoosterIcon className="media-object" />
              </div>
            </div>
            <div className="content">
              <h3>Boosters</h3>
              Get extra points from playing extra games
            </div>
            <div className="extra">
              <span className="play">play</span>
            </div>
          </div>
        </div>
      </div>

      <div className="row game-select">
        <div className="col-4">
          <div
            className="game-item"
            onClick={() => {
              handleScreenChange('riskit');
            }}
          >
            <div className="object svg center">
              <Image fluid src={RiskItIcon} className="media-object" />
            </div>
            <div className="content">
              <h3>
                Risk It (<span className="highlight">{riskit_keys}</span>/
                {riskItBaskets})
              </h3>
              It’s a tricky one, double your points or lose half!
            </div>
            <div className="extra">
              <span className="play">play</span>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="game-item" onClick={() => handleScreenChange('qod')}>
            <div className="object qod">
              <div className="inner">
                <Image fluid src={QODIcon} className="media-object" />
              </div>
            </div>
            <div className="content" style={{ marginBottom: '15px' }}>
              <h3>Question of the day</h3>
              The question with the highest points to start your day!
            </div>
            <div className="extra">
              <span className="play">play</span>
            </div>
          </div>
        </div>
      </div>
    </div>,

    <Modal key={1} open={showOptIn}>
      <Modal.Header>
        <h1>&nbsp;</h1>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description className={`transition pulse`}>
          Receive Question of the Day reminders for this campaign
        </Modal.Description>
      </Modal.Content>

      <Modal.Actions>
        <div className="row" style={{ marginLeft: '0' }}>
          <Button
            className="col"
            primary
            size="huge"
            onClick={() => {
              dispatch(optIn(1));
            }}
          >
            yes
            <span className="fill"></span>
          </Button>

          <Button
            className="col"
            j
            size="huge"
            onClick={() => {
              dispatch(optIn(2));
              setShowOptinMessage(true);
            }}
          >
            No
            <span className="fill"></span>
          </Button>
        </div>
      </Modal.Actions>
    </Modal>,

    <Modal key={2} open={showOptinMessage}>
      <Modal.Header>
        <h1>&nbsp;</h1>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description className={`transition pulse`}>
          You can still enable Question of the day Notifications in settings.
        </Modal.Description>
      </Modal.Content>

      <Modal.Actions>
        <Button
          className="col"
          primary
          size="huge"
          onClick={() => {
            setShowOptinMessage(false);
          }}
        >
          okay
          <span className="fill"></span>
        </Button>
      </Modal.Actions>
    </Modal>
  ];
}

export default Arcade;
