import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import onBoard from './onboard/reducer';
import player from './player/reducer';
import gamePlay from './gamePlay/reducer';
import app from './app/reducer';
import ui from './ui/reducer';
import sprint from './sprint/reducer';
import boosters from './boosters/reducer';
import riskit from './RiskIt/reducer';
import qod from './qod/reducer';

const persistPlayerData = {
  key: 'player',
  storage
};

export default combineReducers({
  ui,
  app,
  onBoard,
  player: persistReducer(persistPlayerData, player),
  gamePlay,
  sprint,
  boosters,
  riskit,
  qod
});
