import React from 'react';
import { Image } from 'semantic-ui-react';
import styled from 'styled-components';

import welcomeImage from '../../images/welcome.svg';

export default function Boards() {
  return (
    <div id="slider__element">
      <Item>
        <div className="image-wrapper">
          <Image src={welcomeImage} />
        </div>
        <p
          style={{
            paddingLeft: '12%',
            paddingRight: '12%',
            textAlign: 'center',
            marginTop: '45px',
            marginBottom: '35px'
          }}
        >
          Answer easy questions and win awesome prizes.
        </p>
      </Item>
    </div>
  );
}

const Item = styled.div``;
