const IMAGE_URL_REGEX = /\b(https?:\/\/\S+(?:png|jpe?g|gif)\S*)\b/i;

export default function(content) {
  if (content === '') return '';

  const isImage = IMAGE_URL_REGEX.test(content);

  if (isImage) {
    return 'image';
  } else {
    return 'standard';
  }
}
