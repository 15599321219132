import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Modal, Button, Input, Checkbox, Image } from 'semantic-ui-react';

import avatar from '../images/avatar-with-shadow.png';
import { changeUsername } from '../modules/player/actions';
import { toggleShowUsernameModal } from '../modules/gamePlay/actions';

const VerifiedModal = ({ open, onClose, username }) => {
  const dispatch = useDispatch();
  const [approveUsername, setApproveUsername] = useState(false);
  const [inputUsername, setInputUsername] = useState('');
  const [activateButton, setActivateButton] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalAlert, setModalAlert] = useState('');
  const {
    api: { status, message }
  } = useSelector(state => state.app);

  const handleChangeUsername = () => {
    setLoadingButton(true);
    setActivateButton(false);

    if (approveUsername) {
      dispatch(toggleShowUsernameModal(false));
    } else {
      dispatch(changeUsername(inputUsername));
    }
  };

  const handleChange = (e, { checked }) => {
    setApproveUsername(checked);
  };

  const handleInputChange = e => setInputUsername(e.target.value);

  useEffect(() => {
    if (approveUsername) {
      setInputUsername(username);
    } else {
      setInputUsername('');
    }
  }, [approveUsername, username]);

  useEffect(() => {
    if (inputUsername !== '') {
      setActivateButton(true);
    } else {
      setActivateButton(false);
    }
  }, [inputUsername]);

  useEffect(() => {
    if (status === false && message !== '') {
      setModalAlert('error');
      setModalMessage(<p>{message}</p>);
      setActivateButton(true);
      setLoadingButton(false);
    }
  }, [status, message]);

  return (
    <StyledModal
      open={open}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      onClose={onClose}
      dimmer="blurring"
      className={modalAlert}
    >
      <Modal.Header>
        <div className="icon">
          <Image fluid src={avatar} />
        </div>
        Verified
        {modalMessage ? (
          modalMessage
        ) : (
          <p>
            Set your user name to get started. You cannot change this once you
            set it
          </p>
        )}
      </Modal.Header>

      <Modal.Content>
        <Input
          size="huge"
          fluid
          placeholder={username || 'Enter username'}
          value={inputUsername}
          onChange={handleInputChange}
        />

        <div style={{ marginTop: '10px', textAlign: 'left' }}>
          <Checkbox label="Use default username" onChange={handleChange} />
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button
          onClick={() => handleChangeUsername()}
          primary={activateButton}
          disabled={!activateButton}
          loading={loadingButton}
          size="huge"
          fluid
        >
          Okay
          <div className="fill"></div>
        </Button>
      </Modal.Actions>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  &.ui.modal > .header:not(.ui) {
    font-size: 2.25rem;
    font-weight: bold;

    p {
      color: #2c2b39;
      line-height: 1.375rem;
      font-weight: normal;
      font-size: 0.938rem;
    }
  }
  .icon {
    width: 75.77px;
    margin: -60px auto 0;
  }
  &.error {
    .ui.input input {
      box-shadow: inset 0px 1px 8px rgba(174, 1, 1, 0.5);
      border-color: rgba(174, 1, 1, 0.5);
    }
  }
`;

export default VerifiedModal;
