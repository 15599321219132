import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BackButton from '../../../components/BackButton';
import { navigateScreen } from '../../../modules/boosters/actions';
import PostCodes from './SocialMedia/PostCodes';

const SocialMedia = ({ ...props }) => {
  const dispatch = useDispatch();
  const { postcodes } = useSelector(state => state.boosters);

  const handleButtonOnClick = () => {
    dispatch(navigateScreen(null));
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-auto">
          <BackButton
            pageName="Social Media"
            onClick={() => handleButtonOnClick()}
          />
        </div>
      </div>

      {postcodes && (
        <PostCodes codes={postcodes} {...{ history: props.history }} />
      )}
    </div>
  );
};

export default SocialMedia;
