import { apiRequest } from '../app/actions';
import { updatePlayer, storeISD } from '../player/actions';
import {
  VERIFY_PLAYER,
  RETURNING_PLAYER,
  NEW_PLAYER,
  INITIATE_OTP,
  INITIATE_OTP_SUCCESS,
  INITIATE_OTP_FAILED,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILED,
  SUBSCRIBE_PLAYER,
  AWAIT_PLAYER_SUBSCRIPTION,
  AUTHENTICATE_PLAYER,
  AUTHENTICATE_PLAYER_RECEIVED,
  AUTHENTICATE_PLAYER_FAILED,
  ONBOARD_COMPLETED,
  INIT_SUB_WIDGET,
  storeOTPToken
} from './actions';
import { UPDATE_MANAGER } from '../app/actions';

const verifyPlayerFlow = ({ dispatch }) => next => action => {
  next(action);

  if (VERIFY_PLAYER === action.type) {
    dispatch(
      apiRequest(
        'get',
        `verifyPlayer/${action.msisdn}`,
        null,
        { type: RETURNING_PLAYER },
        { type: NEW_PLAYER }
      )
    );
  }

  if (RETURNING_PLAYER === action.type) {
    dispatch({ type: INITIATE_OTP });
    // dispatch({ type: SUBSCRIBE_PLAYER });
  }

  if (NEW_PLAYER === action.type) {
    dispatch({ type: SUBSCRIBE_PLAYER });
  }
};

export const OTPFlow = ({ dispatch, getState }) => next => action => {
  next(action);

  const {
    player: { msisdn },
    subscribed
  } = getState().onBoard;

  if (INITIATE_OTP === action.type) {
    dispatch(
      apiRequest(
        'get',
        `initiateOTP/${msisdn}`,
        null,
        { type: INITIATE_OTP_SUCCESS },
        { type: INITIATE_OTP_FAILED }
      )
    );
  }

  if (VERIFY_OTP === action.type) {
    dispatch(
      apiRequest(
        'get',
        `verifyOTP/${msisdn}/${action.pin}`,
        null,
        { type: VERIFY_OTP_SUCCESS },
        { type: VERIFY_OTP_FAILED }
      )
    );
  }

  if (VERIFY_OTP_SUCCESS === action.type) {
    const {
      api: {
        data: { otp_token }
      }
    } = getState().app;

    dispatch(storeOTPToken(otp_token));

    if (subscribed) {
      dispatch({ type: AUTHENTICATE_PLAYER });
    } else {
      // dispatch({ type: SUBSCRIBE_PLAYER });
    }
  }
};

export const subscribePlayerFlow = ({
  dispatch,
  getState
}) => next => action => {
  next(action);

  const {
    ISD
    // player: { msisdn }
  } = getState().onBoard;

  if (SUBSCRIBE_PLAYER === action.type) {
    dispatch(
      {
        type: INIT_SUB_WIDGET
      }
      // apiRequest(
      //   'get',
      //   `subscribeNoOTP/${msisdn}/WEB/vodafone/${ISD}`,
      //   null,
      //   { type: AWAIT_PLAYER_SUBSCRIPTION },
      //   { type: SUBSCRIBE_PLAYER_FAILED }
      // )
    );
  }

  if (AWAIT_PLAYER_SUBSCRIPTION === action.type) {
    dispatch(storeISD(ISD));
    dispatch({ type: UPDATE_MANAGER, useOnboard: true });
  }
};

export const authenticatePlayerFlow = ({
  dispatch,
  getState
}) => next => action => {
  next(action);

  if (AUTHENTICATE_PLAYER === action.type) {
    const {
      player: { msisdn },
      ISD,
      otp_token
    } = getState().onBoard;

    dispatch(
      apiRequest(
        'get',
        `authenticate/${msisdn}/WEB/${otp_token}/${ISD}`,
        null,
        { type: AUTHENTICATE_PLAYER_RECEIVED },
        { type: AUTHENTICATE_PLAYER_FAILED }
      )
    );
  }

  if (AUTHENTICATE_PLAYER_RECEIVED === action.type) {
    const {
      app: {
        api: {
          data: { player_info, token }
        }
      },
      onBoard: { ISD }
    } = getState();

    dispatch(storeISD(ISD));
    dispatch(updatePlayer(player_info, token));
    dispatch({ type: ONBOARD_COMPLETED });
  }
};

const onBoardMdl = [
  verifyPlayerFlow,
  OTPFlow,
  subscribePlayerFlow,
  authenticatePlayerFlow
];

export default onBoardMdl;
