import { apiRequest } from '../app/actions';
import {
  QUESTION,
  QUESTION_RECEIVED,
  QUESTION_FAILED,
  ANSWER,
  ANSWER_RECEIVED,
  ANSWER_FAILED,
  CORRECT_ANSWER,
  WRONG_ANSWER,
  NEXT_QUESTION,
  END_SPRINT,
  storeQuestion,
  updatePoints,
  exhausted,
  reduceSprintCount,
  setQuestionStatus
} from './actions';
import { updatePlayerPoints } from '../player/actions';
import { sexifyIntl } from '../../util/formatPhoneNumber';

export const getTriggerQuestionFlow = ({
  dispatch,
  getState
}) => next => action => {
  next(action);

  if (action.type === QUESTION) {
    const {
      player: {
        token,
        info: { msisdn }
      }
    } = getState();

    dispatch(
      apiRequest(
        'get',
        `getTriggerQuestions/${msisdn.toString().slice(-12)}/${token}`,
        null,
        { type: QUESTION_RECEIVED },
        { type: QUESTION_FAILED }
      )
    );
  }

  if (action.type === QUESTION_RECEIVED) {
    const {
      app: {
        api: {
          status,
          data: { question, question_count }
        }
      }
    } = getState();

    if (status) {
      if (question_count > 0) {
        dispatch(storeQuestion(question[0]));
      } else {
        dispatch(exhausted());
      }
    }
  }
};

export const answerQuestionFlow = ({
  dispatch,
  getState
}) => next => action => {
  next(action);

  if (ANSWER === action.type) {
    let next = 1;
    let getAchievement = '';
    const {
      player: {
        info: { msisdn },
        token
      },
      sprint: {
        question: { question_id, affects_trigger_available },
        count,
        attempt_limit
      }
    } = getState();

    if (count === attempt_limit) {
      next = 0;
      getAchievement = `/${attempt_limit}`;
    }

    dispatch(setQuestionStatus(affects_trigger_available));

    dispatch(
      apiRequest(
        'post',
        `validateTriggerAnswers/${sexifyIntl(
          msisdn
        )}/${next}/${token}${getAchievement}`,
        JSON.stringify({ [question_id]: action.answer }),
        { type: ANSWER_RECEIVED },
        { type: ANSWER_FAILED }
      )
    );
  }

  if (ANSWER_RECEIVED === action.type) {
    const {
      app: {
        api: {
          data: { correct_answers }
        }
      }
    } = getState();

    if (correct_answers === 1) {
      dispatch({ type: CORRECT_ANSWER });
    } else {
      dispatch({ type: WRONG_ANSWER });
    }
  }

  if (CORRECT_ANSWER === action.type) {
    const {
      app: {
        api: {
          data: { points, total_points }
        }
      }
    } = getState();

    dispatch(updatePoints(points));
    dispatch(updatePlayerPoints(total_points));

    dispatch(reduceSprintCount());
  }

  if (WRONG_ANSWER === action.type) {
    const shouldDeductFromQuestionCount = getState().sprint
      .affects_trigger_available;

    if (shouldDeductFromQuestionCount) {
      dispatch(reduceSprintCount());
    }
  }

  if (ANSWER_FAILED === action.type) {
    // TODO: Handle. means question mismatch
  }

  if (NEXT_QUESTION === action.type) {
    const {
      sprint: { count, attempt_limit },
      app: {
        api: { data }
      }
    } = getState();

    if (count === attempt_limit) {
      dispatch({ type: END_SPRINT });
    } else {
      const question = data.next_questions.question[0];
      dispatch(storeQuestion(question));
    }
  }
};

const sprintMdl = [getTriggerQuestionFlow, answerQuestionFlow];

export default sprintMdl;
