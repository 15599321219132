import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Image } from 'semantic-ui-react';

import numberSuffixer from '../../../../util/numberSuffixer';
import { ReactComponent as Arrow } from '../../../../images/back-button.svg';
import PostQuestion from './PostCodes/PostQuestion';
import Completed from './PostCodes/Completed';

const PostCodes = ({ codes, history }) => {
  const { answered } = useSelector(state => state.boosters);
  // const points = useSelector(state => state.player.info.current_points);
  const booster_points = useSelector(state => state.boosters.answered_points);
  const [show, setShow] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const [postData, setPostData] = useState({});
  const selectedItem = useRef([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState();

  const handleShow = (post, i) => {
    setSelectedItemIndex(i);
    setPostData(post);
    setShow(true);
  };
  const handleHide = () => {
    setPostData({});
    setShow(false);
  };

  useEffect(() => {
    if (answered === 'correct') {
      setShowCompleted(true);
      setShow(false);
    }
  }, [answered]);

  useEffect(() => {
    selectedItem.current = selectedItem.current.slice(0, codes.length);
  }, [codes]);

  return [
    <div key={1} className="postcodes-listings mt-4 pt-1">
      {codes.length > 0 ? (
        codes.map(
          (item, i) =>
            item.code && (
              <Post
                key={i}
                onClick={() => handleShow(item, i)}
                ref={elem => (selectedItem.current[i] = elem)}
              >
                <PostImage>
                  <Image
                    fluid
                    src={item.image_path || process.env.REACT_APP_SM_IMAGE}
                  />
                </PostImage>

                <Body>
                  <div className="inner">
                    <DateMeta>{numberSuffixer(codes.length - i)} Code</DateMeta>
                    <Title>{item.code}</Title>
                    <Points>
                      {item.points}pts
                      <Arrow />
                    </Points>
                  </div>
                </Body>
              </Post>
            )
        )
      ) : (
        <p>No post codes</p>
      )}

      <PostQuestion show={show} hide={handleHide} data={postData} />
    </div>,

    <Completed
      key={2}
      points={booster_points}
      show={showCompleted}
      target={selectedItem.current[selectedItemIndex]}
      handleOnConfirm={() => setShowCompleted(false)}
    />
  ];
};

const Post = styled.div`
  display: flex;
  width: 100%;
  border-radius: 4px;
  padding: 19px 20px;
  margin-bottom: 10px;
  background-color: rgba(5, 22, 87, 0.5);
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;
const PostImage = styled.div`
  width: 61px;
  height: 61px;
  margin-right: 14px;
  border-radius: 8px;
  overflow: hidden;
`;
const Body = styled.div`
  flex: 1;
  color: #fff;
`;
const Title = styled.h2`
  color: #fff;
  margin-bottom: 3px;
  font-size: 1.125rem;
  font-weight: bold;
  margin-top: 0;
  position: relative;
  padding-bottom: 3px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 160, 245, 0.1);
  }
`;
const DateMeta = styled.small`
  font-size: 0.75rem;
  display: block;
  margin-bottom: -2px;
`;
const Points = styled.span`
  color: #00a0f5;
  font-weight: bold;
  display: block;
  position: relative;
  font-size: 0.75rem;

  svg {
    transform: rotate(180deg);
    width: 5.8px;
    height: 9.42px;
    position: absolute;
    top: 5px;
    right: 0;
  }
`;

export default PostCodes;
