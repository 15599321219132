import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';

function Select({
  options,
  title,
  handleEditMode,
  inputRef,
  value,
  setInputValue
}) {
  const [selected, setSelected] = useState('');

  const handleOnChange = (e, { value }) => {
    const cover =
      e.target.parentElement.parentElement.parentElement.parentElement;
    setSelected(value);
    setInputValue(value);
    // inputRef.current = e.target;
    // inputRef.current.value = value;
    cover.classList.add('dirty');
    handleEditMode();
  };

  useEffect(() => {
    setSelected(value);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <CustomDropdown
        onChange={handleOnChange}
        value={selected}
        selection
        wrapSelection={false}
        options={options}
        placeholder={`Choose ${title}`}
      />
      <input type="hidden" ref={inputRef} value={selected} />
    </>
  );
}

const CustomDropdown = styled(Dropdown)`
  &.ui.selection.dropdown {
    min-width: 146px;
    padding-right: 3.5em;
    min-height: 0;
    background: transparent;
    padding-left: 0;
    padding-bottom: 0;
    border-radius: 0;
    border-style: none none solid none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: none;
    color: #fff;

    > .dropdown.icon {
      padding-bottom: 0;
      padding-right: 0;
    }

    .active.selected.item {
      color: #00a0f5;
    }
  }
`;

export default Select;
