import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import AreYouReady from './RiskIt/AreYouReady';
import Question from './RiskIt/Question';
import Completed from './RiskIt/Completed';

const RiskIt = props => {
  const question = useSelector(state => state.riskit.question);
  const { current_points } = useSelector(state => state.player.info);
  const [activeComponent, setActiveComponent] = useState('');

  const route = component => setActiveComponent(component);

  useEffect(() => {
    if (Object.entries(question).length > 0) {
      setActiveComponent('risk-it');
    }
  }, [question]);

  return (
    <div className="container">
      {(() => {
        switch (activeComponent) {
          case 'risk-it':
            return (
              <Question
                question={question}
                points={current_points}
                onReady={component => route(component)}
              />
            );

          case 'completed':
            return <Completed {...props} />;

          default:
            return (
              <AreYouReady
                {...props}
                onReady={component => route(component)}
                points={current_points}
              />
            );
        }
      })()}
    </div>
  );
};

export default RiskIt;
