import React from 'react';
import { ToastContainer, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Slide = cssTransition({
  enter: 'slideIn',
  exit: 'slideOut',
  // default to 750ms, can be omitted
  duration: 750
});

function ToastMaster() {
  return <ToastContainer transition={Slide} autoClose={4000} />;
}

export default ToastMaster;
