import {
  STORE_QUESTION,
  PROVE_ANSWER,
  WRONG_ANSWER,
  CORRECT_ANSWER,
  QOD_COMPLETED,
  QOD_RESET
} from './actions';

const initialState = {
  question: {},
  attempts: 0,
  proven: null,
  completed: false,
  score: 0,
  bonus: 0,
  notify: {
    status: false,
    alert: 'info',
    message: ''
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case STORE_QUESTION:
      return { ...state, question: action.question };

    case PROVE_ANSWER:
      return { ...state, proven: null, attempts: state.attempts + 1 };

    case WRONG_ANSWER:
      return { ...state, proven: false };

    case CORRECT_ANSWER:
      return {
        ...state,
        proven: true,
        score: action.score,
        bonus: action.bonus
      };

    case QOD_COMPLETED:
      return { ...state, completed: true };

    case QOD_RESET:
      return {
        ...state,
        completed: false,
        attempts: 0,
        proven: null,
        question: {}
      };

    default:
      return state;
  }
}
