import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import BillPlayerModal from '../../components/BillPlayerModal';
import { billPlayer } from '../../modules/player/actions';
import { notify } from '../../modules/ui/actions';
import { sexifyIntl } from '../../util/formatPhoneNumber';
import Triv5GIF from '../../images/triv5.gif';

function Advertisement() {
  const triv5 = useRef();
  const dispatch = useDispatch();
  const {
    info: { is_billed, msisdn },
    token
  } = useSelector(s => s.player);
  const url = `https://triv5.brainwavegh.com/?TOKEN=${token}&MSISDN=${sexifyIntl(
    msisdn
  )}`;

  const handleClick = event => {
    triv5.current = event.target;

    if (!is_billed) {
      // show bill popup
      dispatch(notify('', 'info'));
    } else {
      window.open(url, '_blank');
    }
  };

  const handleBillSuccess = () => {
    window.open(url, '_blank');
  };

  return (
    <>
      <div className="game-item adv" onClick={handleClick}>
        <img alt="Triv 5" src={Triv5GIF} className="ui fluid image" />
        <div className="extra">
          <span className="play">play</span>
        </div>
      </div>

      <BillPlayerModal
        key={1}
        trigger={triv5}
        onBill={() => dispatch(billPlayer())}
        onBillSuccess={handleBillSuccess}
      />
    </>
  );
}

export default Advertisement;
