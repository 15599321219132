import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import BackButton from '../../components/BackButton';
import GameListings from '../Arcade/Boosters/GameListings';
import SocialMedia from './Boosters/SocialMedia';
import { getPostcodes, navigateScreen } from '../../modules/boosters/actions';

const Boosters = ({ ...props }) => {
  const dispatch = useDispatch();

  const [selectedScreen, setSelectedScreen] = useState(null);

  const { screen, loaded } = useSelector(state => state.boosters);

  const handleButtonOnClick = () => {
    props.history.replace('/arcade');
  };

  const handleLoadContent = game => {
    setSelectedScreen(game);

    switch (game) {
      case 'social-media':
        dispatch(getPostcodes());
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (loaded) {
      dispatch(navigateScreen(selectedScreen));
    }
  }, [loaded, dispatch, selectedScreen]);

  switch (screen) {
    case 'social-media':
      return <SocialMedia {...{ history: props.history }} />;

    default:
      return (
        <div className="container">
          <div className="row">
            <div className="col-auto">
              <BackButton
                pageName="Booster Games"
                onClick={handleButtonOnClick}
              />
            </div>
          </div>

          <GameListings loadContent={view => handleLoadContent(view)} />
        </div>
      );
  }
};

export default Boosters;
