import React, { useState, useRef, useEffect } from 'react';

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = (...args) => savedCallback.current(...args);

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function Countdown({ timer, onComplete }) {
  const [seconds, setSeconds] = useState(timer);
  const [display, setDisplay] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  let counting = useInterval(() => {
    setSeconds(current => current - 1);
    setDisplay({
      ...display,
      days: Math.floor(seconds / (3600 * 24)),
      hours: Math.floor((seconds % (3600 * 24)) / 3600),
      minutes: Math.floor((seconds % 3600) / 60),
      seconds: Math.floor(seconds % 60)
    });

    if (seconds === 0) {
      onComplete({ completed: true });
      console.log('run once');
      clearInterval(counting);
    }
  }, 1000);

  return (
    <div id="campaignCountDown">
      <div>
        {display.days}
        <span>Days</span>
      </div>
      <div>
        {display.hours}
        <span>Hours</span>
      </div>
      <div>
        {display.minutes}
        <span>Mins</span>
      </div>
      <div>
        {display.seconds}
        <span>Secs</span>
      </div>
    </div>
  );
}

export default Countdown;
