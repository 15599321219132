import React, { useState, useRef, createRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Checkbox } from 'semantic-ui-react';

import BackButton from '../components/BackButton';
import { ReactComponent as Notifications } from '../images/notification-solid.svg';
import { ReactComponent as InGameMusic } from '../images/in-game-music.svg';
import { ReactComponent as SoundEffect } from '../images/sound-on.svg';
import { ReactComponent as About } from '../images/about.svg';
import { ReactComponent as HowToPlay } from '../images/how-to-play.svg';
import { ReactComponent as QOD } from '../images/qod-notification.svg';
import { toggleNotification, logout } from '../modules/gamePlay/actions';

function Settings({ history }) {
  const inputRef = useRef([...Array(4)].map(() => createRef()));
  const dispatch = useDispatch();
  const [toggleSlider, setToggleSlider] = useState({});
  const { app } = useSelector(state => state.gamePlay.settings);

  const Loading = document.createElement('DIV');
  Loading.classList.add(
    ...['ui', 'mini', 'active', 'inline', 'loader', 'mr-1']
  );

  const handleButtonOnClick = () => history.push('/');

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleSlide = (event, i) => {
    const slider = event.target;
    const input = slider.parentElement.getElementsByTagName('input');
    const parent = slider.parentElement.parentElement;
    Loading.setAttribute('id', 'loading-' + i);

    if (!input.disabled) {
      inputRef.current[i] = input;
      input.disabled = true;
      parent.insertBefore(Loading, parent.childNodes[0]);

      const name = input[0].parentElement.getAttribute('data-name');

      dispatch(toggleNotification(name, !toggleSlider[name]));
    }
  };

  useEffect(() => {
    setToggleSlider(app);
  }, [app]);

  const toggleSwitch = i => {
    inputRef.current[i].disabled = false;
    const loading = document.getElementById('loading-' + i);
    loading.parentNode.removeChild(loading);
  };

  useEffect(() => {
    if (inputRef.current[0].current !== null) {
      toggleSwitch(0);
    }
  }, [app.notifications]);

  useEffect(() => {
    if (inputRef.current[1].current !== null) {
      toggleSwitch(1);
    }
  }, [app.inGameMusic]);

  useEffect(() => {
    if (inputRef.current[2].current !== null) {
      toggleSwitch(2);
    }
  }, [app.soundEffects]);

  useEffect(() => {
    if (inputRef.current[3].current !== null) {
      toggleSwitch(3);
    }
  }, [app.qod]);

  return (
    <div className="container d-flex flex-column h-100">
      <div className="row">
        <div className="col-auto">
          <BackButton
            pageName="Settings"
            onClick={() => handleButtonOnClick()}
          />
        </div>
      </div>

      <div className="mt-4 pt-1">
        <Box>
          <div className="inner">
            <Header>
              <div className="align-self-center">
                <h4>App Settings</h4>
              </div>
            </Header>

            <hr />

            <List className="d-flex align-items-center">
              <div className="mr-3">
                <Notifications />
              </div>
              <Title>
                General Notifications <small>Reset after 7 days</small>
              </Title>
              <div className="ml-auto d-flex align-items-center">
                <Checkbox
                  toggle
                  checked={toggleSlider.notifications}
                  data-name="notifications"
                  onClick={event => handleSlide(event, 0)}
                />
              </div>
            </List>

            <List className="d-flex align-items-center">
              <div className="mr-3">
                <InGameMusic />
              </div>
              <Title>In-Game Music</Title>
              <div className="ml-auto d-flex align-items-center">
                <Checkbox
                  toggle
                  data-name="inGameMusic"
                  checked={toggleSlider.inGameMusic}
                  onClick={event => handleSlide(event, 1)}
                />
              </div>
            </List>

            <List className="d-flex align-items-center">
              <div className="mr-3">
                <SoundEffect />
              </div>
              <Title>Sound Effects</Title>
              <div className="ml-auto d-flex align-items-center">
                <Checkbox
                  toggle
                  data-name="soundEffects"
                  checked={toggleSlider.soundEffects}
                  onClick={event => handleSlide(event, 2)}
                />
              </div>
            </List>

            <List className="d-flex align-items-center">
              <div className="mr-3">
                <QOD />
              </div>
              <Title>
                QOD Notifications <small>Reset after Campaign</small>
              </Title>
              <div className="ml-auto d-flex align-items-center">
                <Checkbox
                  toggle
                  data-name="qod"
                  checked={toggleSlider.qod}
                  onClick={event => handleSlide(event, 3)}
                />
              </div>
            </List>
          </div>
        </Box>
      </div>

      <div className="mt-4 pt-1">
        <Box>
          <div className="inner">
            <Anchor
              href="https://brainwavegh.com/about.html"
              target="_blank"
              rel="noopener noreferrer"
              className="d-flex align-items-center"
            >
              <div className="mr-3">
                <About />
              </div>
              <Title>About</Title>
            </Anchor>

            <Anchor
              href="https://brainwavegh.com/how.html"
              target="_blank"
              rel="noopener noreferrer"
              className="d-flex align-items-center"
            >
              <div className="mr-3">
                <HowToPlay />
              </div>
              <Title>How to play</Title>
            </Anchor>

            <Anchor
              href="https://www.brainwavegh.com/terms-conditions.html"
              target="_blank"
              rel="noopener noreferrer"
              className="d-flex align-items-center"
            >
              <div className="mr-3">
                <About />
              </div>
              <Title>Terms &amp; Conditions</Title>
            </Anchor>
          </div>
        </Box>
      </div>

      <div className="d-flex justify-content-center mt-auto mb-4">
        <Button className="noButton" onClick={() => handleLogout()}>
          LOG OUT
        </Button>
      </div>
    </div>
  );
}
const Title = styled(Button)`
  &.ui.button {
    font-family: ProximaNovaRg, -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
    font-size: 0.875rem;
    padding: 0;
    background-color: transparent;
    text-transform: none;
    width: auto;
    height: auto;
    font-weight: normal;
    line-height: 15px;

    &:focus {
      background-color: transparent;
      color: #fff;
      background-image: '' !important;
      box-shadow: '' !important;
    }
  }

  small {
    display: block;
    font-size: 8px;
    text-align: left;
  }
`;
const List = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .ui.fitted.toggle.checkbox {
    width: 2.5rem;

    label::before {
      width: 2.5rem;
    }

    input:checked ~ label::after {
      left: 1rem;
    }

    label {
      margin-bottom: 0;
    }
  }

  .ui.checkbox.toggle {
    label::before,
    label:hover::before,
    input:focus ~ label:before {
      border: 0.094rem solid #fff;
      background-color: #fff;
    }

    input:focus:checked ~ label:before,
    &.checked label::before {
      background-color: #004fd4 !important;
    }
  }

  button:hover {
    background: transparent !important;
    color: #fff !important;
  }
`;
const Anchor = styled.a`
  &:hover {
    background-color: transparent;
    text-decoration: none;
  }
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  button:hover {
    background: transparent !important;
    color: #fff !important;
  }
`;
const Header = styled.div`
  display: flex;

  h4 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0;
  }
`;
const Box = styled.div`
  color: #fff;
  border-radius: 4px;
  background-color: rgba(5, 22, 87, 0.8);

  .inner {
    padding: 15px;
  }

  hr {
    opacity: 0.2;
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
`;

export default Settings;
