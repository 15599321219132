import {
  GET_PLAYER,
  GET_PLAYER_SUCCESS,
  GET_PLAYER_ERROR,
  BILL_PLAYER,
  BILL_SUCCESS,
  BILL_ERROR,
  UPDATE_PLAYER,
  CHANGE_USERNAME,
  CHANGE_USERNAME_RECEIVED,
  CHANGE_USERNAME_FAILED,
  UPLOAD_PROFILE_IMAGE,
  UPLOAD_PROFILE_IMAGE_RECEIVED,
  UPLOAD_PROFILE_IMAGE_FAILED,
  CHANGE_PLAYER_INFO,
  CHANGE_PLAYER_INFO_RECEIVED,
  CHANGE_PLAYER_INFO_FAILED,
  updatePlayer,
  updateUsername,
  updateImage,
  logUpdateField
} from './actions';
import { apiRequest } from '../app/actions';
import { sexifyIntl } from '../../util/formatPhoneNumber';
import { notify } from '../ui/actions';
import { toggleShowUsernameModal, getCampaign } from '../gamePlay/actions';
import { resetOnBoard } from '../onboard/actions';

export const getUserFlow = ({ dispatch, getState }) => next => action => {
  next(action);

  if (GET_PLAYER === action.type) {
    const {
      player: {
        token,
        info: { msisdn }
      }
    } = getState();

    dispatch(
      apiRequest(
        'get',
        `getUserInfo/${sexifyIntl(msisdn)}/${token}`,
        null,
        { type: GET_PLAYER_SUCCESS },
        { type: GET_PLAYER_ERROR }
      )
    );
  }

  if (GET_PLAYER_SUCCESS === action.type) {
    const {
      app: {
        api: { data }
      },
      player: { token }
    } = getState();

    dispatch(updatePlayer(data, token));
  }

  if (UPDATE_PLAYER === action.type) {
    dispatch(getCampaign());
    if (getState().onBoard.progress !== '') dispatch(resetOnBoard());
  }
};

export const billPlayerFlow = ({ dispatch, getState }) => next => action => {
  next(action);

  if (BILL_PLAYER === action.type) {
    const {
      player: {
        token,
        info: { msisdn }
      }
    } = getState();

    dispatch(
      apiRequest(
        'put',
        `billPlayer/${sexifyIntl(msisdn)}/${token}`,
        null,
        { type: BILL_SUCCESS },
        { type: BILL_ERROR }
      )
    );
  }

  if (BILL_ERROR === action.type) {
    const {
      app: {
        api: { message }
      }
    } = getState();

    dispatch(notify(message, 'error'));
  }
};

export const changePlayerInfo = ({ dispatch, getState }) => next => action => {
  next(action);

  if (CHANGE_USERNAME === action.type) {
    const {
      player: {
        token,
        info: { msisdn }
      }
    } = getState();

    dispatch(
      apiRequest(
        'put',
        `changeUsername/${sexifyIntl(msisdn)}/${token}/${action.username}`,
        null,
        { type: CHANGE_USERNAME_RECEIVED },
        { type: CHANGE_USERNAME_FAILED }
      )
    );
  }

  if (CHANGE_USERNAME_RECEIVED === action.type) {
    const {
      player: { tempUsername }
    } = getState();

    dispatch(updateUsername(tempUsername));
    dispatch(toggleShowUsernameModal(false));
  }

  if (CHANGE_PLAYER_INFO === action.type) {
    const {
      player: {
        token,
        info: { msisdn }
      }
    } = getState();

    dispatch(logUpdateField(action.meta.info, action.value));

    dispatch(
      apiRequest(
        'put',
        `updatePlayerInfo/${sexifyIntl(msisdn)}/${token}`,
        JSON.stringify({ [action.meta.info]: action.value }),
        { type: CHANGE_PLAYER_INFO_RECEIVED, field: action.meta.info },
        { type: CHANGE_PLAYER_INFO_FAILED }
      )
    );
  }

  if (CHANGE_PLAYER_INFO_RECEIVED === action.type) {
    dispatch(logUpdateField(action.field, 'done'));
  }
};

export const changePlayerImage = ({ dispatch, getState }) => next => action => {
  next(action);

  if (UPLOAD_PROFILE_IMAGE === action.type) {
    const {
      player: {
        token,
        info: { msisdn }
      }
    } = getState();

    dispatch(
      apiRequest(
        'put',
        `updateProfileImage/${sexifyIntl(msisdn)}/${token}`,
        JSON.stringify({ base64Img: action.meta.imgBase64 }),
        { type: UPLOAD_PROFILE_IMAGE_RECEIVED },
        { type: UPLOAD_PROFILE_IMAGE_FAILED }
      )
    );
  }

  if (UPLOAD_PROFILE_IMAGE_RECEIVED === action.type) {
    const { image_path } = getState().app.api.data;
    dispatch(updateImage(image_path));
  }
};

const playerMdl = [
  getUserFlow,
  billPlayerFlow,
  changePlayerInfo,
  changePlayerImage
];

export default playerMdl;
