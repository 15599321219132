import React, { useEffect } from 'react';

import { ReactComponent as TimerSVG } from './timer.svg';
import { ReactComponent as CircleSVG } from './circle.svg';

function Timer({ duration, onCompleted, state }) {
  useEffect(() => {
    let interval = null;
    let countDown = duration;
    let hand = document.getElementById('secondHand');
    let circle = document.getElementById('secondCircle');
    if (state === 'play') {
      circle.style.animationDuration = `${duration}s`;
      hand.style.animationDuration = `${duration}s`;
      /** Start Countdown */
      interval = setInterval(() => {
        countDown = countDown - 1;
        if (countDown === 0) {
          clearInterval(interval);
          onCompleted();
        }
      }, 1000);
    } else if (state === 'resume') {
    } else {
      // stop
      circle.style.animationPlayState = 'paused';
      hand.style.animationPlayState = 'paused';
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [duration, onCompleted, state]);

  return (
    <div id="Timer">
      <TimerSVG />
      <CircleSVG />
    </div>
  );
}

Timer.defaultProps = {
  state: 'play'
};

export default Timer;
