import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import PinInput from '../../components/pinInput';
import { ReactComponent as BackButton } from '../../images/back-button.svg';
import { resendOTP, verifyOTP } from '../../modules/onboard/actions';
import { sexify } from '../../util/formatPhoneNumber';

function OneTimePin() {
  const [startCount, setStartCount] = useState(true);
  const [resendCountDown, setResendCountDown] = useState(10);
  const [resendActive, setResendActive] = useState(false);
  const [pin, setPin] = useState(null);
  const [activateDoneButton, setActivateDoneButton] = useState(false);
  const [loadingDoneButton, setLoadingDoneButton] = useState(false);
  const [invalidPin, setInvalidPin] = useState('');

  const dispatch = useDispatch();
  const {
    progress,
    player: { msisdn }
  } = useSelector(state => state.onBoard);

  const onPinComplete = (value, index) => {
    setPin(value);
  };

  const handleInputChange = (pin, i) => {
    if (pin.length === 4) {
      setActivateDoneButton(true);
    } else {
      setActivateDoneButton(false);
    }
  };

  const handleVerifyPin = () => {
    setLoadingDoneButton(true);
    setResendActive(false);
    setInvalidPin('');
    dispatch(verifyOTP(pin));
  };

  useEffect(() => {
    let interval = null;
    if (startCount) {
      interval = setInterval(() => {
        setResendCountDown(resendCount => resendCount - 1);
      }, 1000);
      if (resendCountDown <= 0) {
        setStartCount(false);
        setResendActive(true);
      }
    } else if (!startCount && resendCountDown < 0) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [startCount, resendCountDown]);

  useEffect(() => {
    if (progress === 'invalid-OTP') {
      setLoadingDoneButton(false);
      setResendActive(true);
      setInvalidPin('invalid-pin');
    }
  }, [progress]);

  return (
    <div className="container">
      <div className="btn-back">
        <BackButton />
      </div>

      <div className="row justify-content-center">
        <p
          style={{
            maxWidth: '265px',
            textAlign: 'center',
            marginBottom: '34px',
            marginTop: '30px'
          }}
        >
          Enter the One Time password has been sent to{' '}
          <strong className="highlight">{sexify(msisdn)}</strong>.
        </p>
      </div>

      <div className={`px-4 ${invalidPin}`}>
        <PinInput
          secret
          length={4}
          type="tel"
          focus={true}
          initialValue=""
          onChange={(value, index) => handleInputChange(value, index)}
          onComplete={(value, index) => onPinComplete(value, index)}
        />
        {/* <span>The pin you have provided is invalid.</span> */}
      </div>

      <div className="row justify-content-center" style={{ marginTop: '80px' }}>
        <div className="p-typography">
          Didn’t get the OTP?{' '}
          <ResendOTP
            active={resendActive}
            countDown={resendCountDown}
            {...{ dispatch, progress }}
          />
        </div>

        <div className="button__container w-100 px-4 mt-3">
          <Button
            size="huge"
            fluid
            disabled={
              !activateDoneButton || (loadingDoneButton && activateDoneButton)
            }
            primary={activateDoneButton && !loadingDoneButton}
            loading={loadingDoneButton}
            onClick={() => handleVerifyPin()}
            style={{ marginTop: '7px', marginBottom: '25px' }}
          >
            DONE
            <div className="fill"></div>
          </Button>
        </div>
      </div>
    </div>
  );
}

const ResendOTP = ({ active, countDown, dispatch, progress }) => {
  const [loading, setLoading] = useState(false);
  const [hideCountDown, setHideCountDown] = useState(false);

  const handleOnclick = () => {
    setLoading(true);
    dispatch(resendOTP());
  };

  useEffect(() => {
    if (progress === 'awaiting-user') {
      setLoading(false);
    }
    if (progress === 'OTP-verification') {
      setHideCountDown(true);
    }
  }, [progress]);

  return active ? (
    <Button
      className="resend"
      size="mini"
      loading={loading}
      onClick={() => handleOnclick()}
    >
      {' '}
      RESEND OTP{' '}
    </Button>
  ) : (
    <span className="resend counting">
      {' '}
      RESEND OTP {!hideCountDown && `(${countDown})`}
    </span>
  );
};

export default OneTimePin;
