export const HIDE_SPINNER = '[ui] Hide spinner';
export const SHOW_SPINNER = '[ui] Show spinner';
export const SHOW_COVER = '[ui] Show cover';
export const HIDE_COVER = '[ui] Hide cover';
export const SHOW_BILLING = '[ui] Notify billing';
export const HIDE_BILLING = '[ui] Hide billing';
export const NOTIFY = '[ui] Notify';
export const HIDE_NOTIFY = '[ui] Hide notify';

export const showCover = () => ({
  type: SHOW_COVER
});

export const hideCover = () => ({
  type: HIDE_COVER
});

export const showSpinner = () => ({
  type: SHOW_SPINNER
});

export const hideSpinner = () => ({
  type: HIDE_SPINNER
});

export const showBilling = () => ({
  type: SHOW_BILLING
});

export const hideBilling = () => ({
  type: HIDE_BILLING
});

export const notify = (message, alert) => ({
  type: NOTIFY,
  message,
  meta: {
    alert
  }
});

export const hideNotify = () => ({
  type: HIDE_NOTIFY
});
