import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';

import Image from './QuestionItems/Image';
import getFormat from '../../util/getFormat';

function QuestionItem({ image, content, points, counting, ...props }) {
  const [format, setFormat] = useState('');
  // console.log(questionFormat)

  useEffect(() => {
    if (props.raw !== '') {
      const format = getFormat(props.raw);
      console.log('check format: ', format);
      setFormat(format);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="row">
      <div className="col">
        <QuestionBox
          className={image && 'has-image'}
          overRideStyle={props.styled}
        >
          {image}
          {counting}
          <div className="d-flex">
            {typeof points === 'object' && points.constructor === Object ? (
              points
            ) : (
              <Point className="mt-2">
                <NumberFormat
                  displayType="text"
                  value={points ? points : '0.00'}
                  thousandSeparator=","
                  suffix=" pts"
                />
              </Point>
            )}
          </div>

          <div className="d-fex">
            <div className="mt-4">
              {(() => {
                switch (format) {
                  case 'image':
                    return (
                      <Image
                        key={counting}
                        {...{
                          content: props.raw,
                          setTimer: props.controlTimer
                        }}
                      />
                    );

                  case 'standard':
                    return content;

                  default:
                    break;
                }
              })()}
            </div>
          </div>
        </QuestionBox>
      </div>
    </div>
  );
}

const QuestionBox = styled.div`
  border: 10px solid #fbb200;
  border-radius: 10px;
  background-color: #fff;
  color: #2c2b39;
  padding: 23px 15px 21px;
  min-height: 250px;
  margin-top: 22px;
  position: relative;
  ${props => props.overRideStyle}

  p {
    color: #2c2b39;
    text-align: center;
    max-width: 320px;
  }
`;

const Point = styled.div`
  background-color: #004fd4;
  border: 1px solid #00a0f5;
  border-radius: 4px;
  padding: 2px 15px;
  margin: 0 auto;
  color: #fff;
  display: inline-block;
`;

export default QuestionItem;
