import React from 'react';

function Input({
  title,
  handleOnChange,
  onFocus,
  onBlur,
  inputValue,
  inputRef
}) {
  return (
    <div className="ui input">
      <input
        placeholder={title}
        onChange={handleOnChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={inputValue}
        ref={inputRef}
      />
      <span className="underline"></span>
    </div>
  );
}

export default Input;
