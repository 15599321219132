import React from 'react';
import { useSelector } from 'react-redux';

import Points from '../../../../../components/Points';
import BackButton from '../../../../../components/BackButton';
import Popeye from '../../../../../components/Popeye';
import Item from './Item';

const PostQuestion = ({ data, show, hide }) => {
  const points = useSelector(state => state.player.info.current_points);

  return (
    <Popeye show={show} align="top">
      <div className="container">
        <div className="row page-control">
          <div className="col-auto mr-auto">
            <BackButton close={true} onClick={() => hide()} />
          </div>
          <div className="col-auto ml-auto">
            <Points points={points} />
          </div>
        </div>

        <Item {...data} />
      </div>
    </Popeye>
  );
};

export default PostQuestion;
