import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { ReactComponent as ErrorIcon } from '../../../images/errorIcon.svg';

function NoQuestion({ show, onClose }) {
  return (
    <StyledModal open={show} closeOnEscape={false} closeOnDimmerClick={false}>
      <Modal.Header>
        <div className="icon">
          <ErrorIcon />
        </div>
        <p>
          Sorry, there are no Risk It questions at the moment, kindly check back
          later!
        </p>
      </Modal.Header>
      <Modal.Actions>
        <Button onClick={onClose} primary size="huge" fluid>
          Okay
          <div className="fill"></div>
        </Button>
      </Modal.Actions>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  &.ui.modal > .header:not(.ui) {
    font-size: 2.25rem;
    font-weight: bold;

    p {
      color: #2c2b39;
      line-height: 1.375rem;
      font-weight: normal;
      font-size: 0.938rem;
      margin-top: 15px;
    }
  }
  .icon {
    width: 75.77px;
    margin: -60px auto 0;
  }
  &.error {
    .ui.input input {
      box-shadow: inset 0px 1px 8px rgba(174, 1, 1, 0.5);
      border-color: rgba(174, 1, 1, 0.5);
    }
  }
`;

export default NoQuestion;
