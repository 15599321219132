import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, Image, Button } from 'semantic-ui-react';
import styled from 'styled-components';

import UnlockedIcon from '../images/unlocked.png';

function UnlockedModal({ open, onClick }) {
  const { unlocked } = useSelector(state => state.gamePlay);

  const achievements = () => {
    if (unlocked.length > 0) {
      return unlocked.map((item, i) => {
        const commaAnd = i ? (i === unlocked.length - 1 ? ', ' : ' and ') : '';
        return (
          <React.Fragment key={i}>
            {commaAnd}
            <strong>{item.name}</strong>
          </React.Fragment>
        );
      });
    } else {
      return '';
    }
  };

  return (
    <StyledModal
      open={open}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      dimmer="blurring"
    >
      <Modal.Header>
        <div className="icon">
          <Image fluid src={UnlockedIcon} />
        </div>
        Congratulations
        <p>Wow! You have unlocked {achievements()} achievement.</p>
      </Modal.Header>

      <Modal.Actions>
        <Button onClick={onClick} primary size="huge" fluid>
          Okay
          <div className="fill"></div>
        </Button>
      </Modal.Actions>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  &.ui.modal > .header:not(.ui) {
    font-size: 1.125rem;
    font-weight: bold;

    p {
      color: #2c2b39;
      line-height: 1.375rem;
      font-weight: normal;
      font-size: 1rem;
      margin: 10px auto;
      width: 260px;
    }
  }
  .icon {
    width: 76px;
    margin: 0 auto 15px;
  }
`;

export default UnlockedModal;
