import {
  LEADERBOARD,
  LEADERBOARD_RECEIVED,
  LEADERBOARD_FAILED,
  GET_ACHIEVEMENTS,
  GET_ACHIEVEMENTS_RECEIVED,
  GET_ACHIEVEMENTS_FAILED,
  ACHIEVEMENT_UNLOCKED,
  GET_CAMPAIGN,
  GET_CAMPAIGN_RECEIVED,
  GET_CAMPAIGN_FAILED,
  LOGOUT,
  OPT_IN,
  OPT_IN_SUCCESS,
  OPT_IN_FAILURE,
  CHECK_QUESTION_FORMAT,
  IMAGE_URL_REGEX,
  TOGGLE_NOTIFICATIONS,
  storeLeaderboard,
  storeAchievements,
  storeCampaign,
  updateImage,
  campaignStatus,
  newCampaignOptIn,
  setQuestionFormat,
  setNotification,
  updateCountdown
} from './actions';
import { apiRequest } from '../app/actions';
import { updateAchievement, playerUnauthorised } from '../player/actions';
import { hideCover } from '../ui/actions';
import { sexifyIntl } from '../../util/formatPhoneNumber';

export const leaderboardFlow = ({ dispatch, getState }) => next => action => {
  next(action);

  if (LEADERBOARD === action.type) {
    dispatch(
      apiRequest(
        'get',
        `getLeaderBoard/${0}/${0}/${30}`,
        null,
        { type: LEADERBOARD_RECEIVED },
        { type: LEADERBOARD_FAILED }
      )
    );
  }

  if (LEADERBOARD_RECEIVED === action.type) {
    const {
      app: {
        api: { data }
      }
    } = getState();

    dispatch(storeLeaderboard(data));
  }
};

export const getAchievementsFlow = ({
  dispatch,
  getState
}) => next => action => {
  next(action);

  if (GET_ACHIEVEMENTS === action.type) {
    dispatch(
      apiRequest(
        'get',
        `getAvailableAchievements`,
        null,
        { type: GET_ACHIEVEMENTS_RECEIVED },
        { type: GET_ACHIEVEMENTS_FAILED }
      )
    );
  }

  if (GET_ACHIEVEMENTS_RECEIVED === action.type) {
    const {
      app: {
        api: { data: achievements }
      }
    } = getState();

    dispatch(storeAchievements(achievements));
  }

  if (ACHIEVEMENT_UNLOCKED === action.type) {
    dispatch(updateAchievement(action.achievement.code));
  }
};

export const getCampaignFlow = ({ dispatch, getState }) => next => action => {
  next(action);

  if (GET_CAMPAIGN === action.type) {
    // dispatch({type: OPT_IN})
    // TODO: check if there is campaign already
    dispatch(
      apiRequest(
        'get',
        'getCurrentCampaign',
        null,
        { type: GET_CAMPAIGN_RECEIVED },
        { type: GET_CAMPAIGN_FAILED }
      )
    );
  }

  if (GET_CAMPAIGN_RECEIVED === action.type) {
    const {
      app: {
        api: { data }
      },
      gamePlay: { campaign },
      player: {
        info: { campaign_optin_status }
      }
    } = getState();

    if (data.name !== null) {
      dispatch(storeCampaign(data));
      dispatch(campaignStatus(true));

      const opt_in = `${data.name.replace(/\s+/g, '').toLowerCase()}_opt_in`;
      if (!campaign_optin_status) {
        if (campaign.history[opt_in] === undefined) {
          dispatch(newCampaignOptIn({ [opt_in]: '' }));
          setNotification('qod', true);
        }
      }
    } else {
      dispatch(updateImage(data.image_path));
      dispatch(campaignStatus(false));
      dispatch(updateCountdown(data.next_campaign_start));
      setNotification('qod', false);
    }
    dispatch(hideCover());
  }
};

export const manageSettings = ({ dispatch, getState }) => next => action => {
  next(action);

  if (TOGGLE_NOTIFICATIONS === action.type) {
    const {
      player: {
        info: { msisdn },
        token
      }
    } = getState();

    let route = '';

    if (action.meta.name === 'notifications') {
      route = action.toggle
        ? `mute/${sexifyIntl(msisdn)}/${token}`
        : `unmute/${sexifyIntl(msisdn)}/${token}`;
    }

    if (action.meta.name === 'qod') {
      route = action.toggle
        ? `playerCampaignOptIn/${sexifyIntl(msisdn)}/${token}`
        : `playerCampaignOptOut/${sexifyIntl(msisdn)}/${token}`;
    }

    if (route !== '') {
      dispatch(
        apiRequest(
          'put',
          route,
          null,
          setNotification(action.meta.name, action.toggle),
          setNotification(action.meta.name, false)
        )
      );
    } else {
      dispatch(setNotification(action.meta.name, action.toggle));
    }
  }
};

export const optInFlow = ({ dispatch, getState }) => next => action => {
  next(action);

  if (OPT_IN === action.type) {
    let base = '';

    if (action.status === 1) {
      base = 'playerCampaignOptIn';
    } else if (action.status === 2) {
      base = 'playerCampaignOptOut';
    }

    const {
      player: {
        info: { msisdn },
        token
      }
    } = getState();

    if (base !== '') {
      dispatch(
        apiRequest(
          'put',
          `${base}/${sexifyIntl(msisdn)}/${token}`,
          null,
          { type: OPT_IN_SUCCESS },
          { type: OPT_IN_FAILURE }
        )
      );
    }
  }
};

export const questionFormatFlow = ({ dispatch }) => next => action => {
  next(action);

  if (CHECK_QUESTION_FORMAT === action.type) {
    const currentQuestion = action.question;
    const isImage = IMAGE_URL_REGEX.test(currentQuestion);

    if (isImage && currentQuestion.match(IMAGE_URL_REGEX).length > 0) {
      dispatch(setQuestionFormat('image'));
    } else {
      dispatch(setQuestionFormat('standard'));
    }
  }
};

export const logoutFlow = ({ dispatch }) => next => action => {
  next(action);

  if (LOGOUT === action.type) {
    dispatch(playerUnauthorised());
  }
};

const gamePlayMdl = [
  questionFormatFlow,
  leaderboardFlow,
  getAchievementsFlow,
  getCampaignFlow,
  manageSettings,
  optInFlow,
  logoutFlow
];

export default gamePlayMdl;
