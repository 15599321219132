import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'semantic-ui-react';

import Ready from './QOD/Ready';
import Question from './QOD/Question';
import Completed from './QOD/Completed';

const QOD = ({ history }) => {
  const { current_points, qod_answered, username } = useSelector(
    state => state.player.info
  );
  const { question, completed, score, bonus } = useSelector(state => state.qod);
  const { is_qod_active } = useSelector(state => state.gamePlay.campaign.meta);
  const [activeComponent, setActiveComponent] = useState('');
  const [QODNotification, setQODNotification] = useState({});
  const [showAlert, setShowAlert] = useState(false);

  const handleOnBack = () => {
    history.push('/arcade');
  };

  const route = component => setActiveComponent(component);

  useEffect(() => {
    if (question && Object.entries(question).length > 0) {
      if (question.chances > 0 && question.is_answered === 0) {
        setActiveComponent('question');
      } else if (question.chances <= 0 && question.is_answered === 0) {
        // you have exhausted your chances
        setQODNotification({
          title: 'Exhausted Chances',
          message: (
            <p>
              You have exhausted all your chances to answer Question of the Day.
              Comeback tomorrow.
            </p>
          )
        });
      }
    } else if (
      question === 'no-question' ||
      (is_qod_active !== undefined && !is_qod_active)
    ) {
      // no QOD
      setQODNotification({
        title: 'No QOD',
        message: (
          <p>
            Question of the Day is not available at this moment. Kindly check
            back later.
          </p>
        )
      });
    }
  }, [question, is_qod_active]);

  useEffect(() => {
    if (qod_answered) {
      // you have already answered your QOD
      setQODNotification({
        title: 'QOD Answered',
        message: (
          <p>
            You have already answered the Question of the Day. Comeback
            tomorrow.
          </p>
        )
      });
    }
  }, [qod_answered]);

  useEffect(() => {
    if (Object.entries(QODNotification).length > 0) {
      setShowAlert(true);
    }
  }, [QODNotification]);

  useEffect(() => {
    if (completed) {
      setActiveComponent('completed');
    }
  }, [completed]);

  return (
    <div className="container">
      {(() => {
        switch (activeComponent) {
          case 'question':
            return (
              <Question
                {...{
                  current_points,
                  question,
                  route: component => route(component)
                }}
              />
            );

          case 'completed':
            return (
              <Completed
                {...{
                  points: score,
                  bonus,
                  username,
                  handleOnConfirm: handleOnBack
                }}
              />
            );

          default:
            return (
              <Ready
                points={current_points}
                onBack={handleOnBack}
                okAction={component => route(component)}
              />
            );
        }
      })()}

      <Modal open={showAlert} className={`notification error`}>
        <Modal.Header>
          <h1>{QODNotification.title}</h1>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description className={`transition pulse`}>
            {QODNotification.message}
          </Modal.Description>
        </Modal.Content>

        <Modal.Actions>
          <Button
            primary
            size="huge"
            onClick={() => {
              setShowAlert(false);
              setQODNotification({});
              handleOnBack();
            }}
          >
            Okay
            <span className="fill"></span>
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default QOD;
