export const VERIFY_PLAYER = '[on_board] Verify Player';
export const RETURNING_PLAYER = '[on_board] Returning player';
export const NEW_PLAYER = '[on_board] New player';
export const INITIATE_OTP = '[on_board] Initiate OTP';
export const INITIATE_OTP_SUCCESS = '[on_board] Awaiting player response';
export const INITIATE_OTP_FAILED = '[on_board] Failed to send OTP';
export const VERIFY_OTP = '[on_board] Verify OTP';
export const VERIFY_OTP_SUCCESS = '[on_board] OTP verified';
export const VERIFY_OTP_FAILED = '[on_board] OTP failed';
export const STORE_OTP_TOKEN = '[on_board] Store OTP token';
export const SUBSCRIBE_PLAYER = '[on_board] Subscribe player';
export const SUBSCRIBE_PLAYER_RECEIVED = '[on_board] Subscription received';
export const SUBSCRIBE_PLAYER_FAILED = '[on_board] Subscription failed';
export const AUTHENTICATE_PLAYER = '[on_board] Authenticate player';
export const AUTHENTICATE_PLAYER_RECEIVED =
  '[on_board] Authentication received';
export const AUTHENTICATE_PLAYER_FAILED = '[on_board] Authentication failed';
export const ONBOARD_COMPLETED = '[on_board] Completed';
export const SHOW_USERNAME_MODAL = '[on_board] Show username modal';
export const RESET_ONBOARD = '[on_board] Reset';
export const AWAIT_PLAYER_SUBSCRIPTION = '[on_board] Waiting for player.';
export const INIT_SUB_WIDGET = '[on_board] Initiate Subscription Widget.';

export const verifyPlayer = msisdn => ({
  type: VERIFY_PLAYER,
  msisdn,
  meta: { ISD: msisdn + new Date().valueOf() }
});

export const resendOTP = () => ({
  type: INITIATE_OTP
});

export const verifyOTP = pin => ({
  type: VERIFY_OTP,
  pin
});

export const storeOTPToken = token => ({
  type: STORE_OTP_TOKEN,
  token
});

export const resetOnBoard = () => ({
  type: RESET_ONBOARD
});
