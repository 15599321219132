import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Detail, Meta, Status } from './DetailComponent';

function AchievementItem({ code, count }) {
  const { achievements } = useSelector(state => state.gamePlay);

  const findAchievementName = achievement => {
    if (Object.entries(achievements).length <= 0) {
      return achievement;
    }

    const found = achievements.find(item => item.code === achievement);

    if (found !== undefined) return found.name;
    else return achievement;
  };

  return (
    <Detail>
      {code ? (
        [
          <Meta key="meta">{findAchievementName(code)}</Meta>,
          <ExtendStatus key="status" className="d-flex">
            {count}
          </ExtendStatus>
        ]
      ) : (
        <Meta>No achievements just yet</Meta>
      )}
    </Detail>
  );
}

const ExtendStatus = styled(Status)`
  color: #ffc800 !important;
`;

export default AchievementItem;
