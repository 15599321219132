import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { ReactComponent as UpdateIcon } from '../images/content-update.svg';

const Alert = ({ text, buttonText, type, onClick }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!onClick) {
      const timer = setTimeout(() => {
        dispatch({ type });
      }, 6000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <StyledModal
      open={open}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      onClose={() => setOpen(false)}
    >
      <Modal.Header>
        <div className="icon">
          <UpdateIcon />
        </div>
        <p>{text}</p>
      </Modal.Header>
      <Modal.Actions>
        {buttonText && (
          <Button
            positive={!disabled}
            loading={loading}
            disabled={disabled}
            onClick={() => {
              setLoading(true);
              setDisabled(true);
              onClick();
            }}
          >
            {buttonText}
          </Button>
        )}
      </Modal.Actions>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  &.ui.modal > .header:not(.ui) {
    font-size: 2.25rem;
    font-weight: bold;

    p {
      color: #2c2b39;
      line-height: 1.375rem;
      font-weight: normal;
      font-size: 0.938rem;
      margin-top: 15px;
    }
  }
  .icon {
    width: 75.77px;
    margin: -60px auto 0;
  }
  &.error {
    .ui.input input {
      box-shadow: inset 0px 1px 8px rgba(174, 1, 1, 0.5);
      border-color: rgba(174, 1, 1, 0.5);
    }
  }
`;

export default Alert;
