import {
  VERIFY_PLAYER,
  RETURNING_PLAYER,
  NEW_PLAYER,
  INITIATE_OTP,
  INITIATE_OTP_SUCCESS,
  INITIATE_OTP_FAILED,
  VERIFY_OTP,
  VERIFY_OTP_FAILED,
  STORE_OTP_TOKEN,
  ONBOARD_COMPLETED,
  SHOW_USERNAME_MODAL,
  RESET_ONBOARD,
  INIT_SUB_WIDGET
} from './actions';

const initialState = {
  progress: 'awaiting-player-number',
  player: {},
  subscribed: null,
  otp_token: ''
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case VERIFY_PLAYER:
      return {
        ...state,
        progress: 'verify-player',
        ISD: action.meta.ISD,
        player: { ...state.player, msisdn: action.msisdn }
      };

    case RETURNING_PLAYER:
      return { ...state, progress: 'returning-player', subscribed: true };

    case NEW_PLAYER:
      return { ...state, progress: 'new-player', subscribed: false };

    case INITIATE_OTP:
      return { ...state, progress: 'OTP-initiated' };

    case INITIATE_OTP_SUCCESS:
      return { ...state, progress: 'awaiting-user' };

    case INITIATE_OTP_FAILED:
      return { progress: 'OTP-failed' };

    case VERIFY_OTP:
      return { ...state, progress: 'OTP-verification' };

    case VERIFY_OTP_FAILED:
      return { ...state, progress: 'invalid-OTP' };

    case STORE_OTP_TOKEN:
      return { ...state, otp_token: action.token };

    case ONBOARD_COMPLETED:
      return { ...state, progress: 'completed' };

    case SHOW_USERNAME_MODAL:
      return { ...state, progress: 'show-username-modal' };

    case RESET_ONBOARD:
      return { ...state, progress: '' };

    case INIT_SUB_WIDGET:
      return {
        ...state,
        progress: 'initiate_sub_widget'
      };

    default:
      return state;
  }
}
