import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Image } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  QuestionsControl,
  QuestionItem,
  QuestionOptions,
  setupOptions
} from '../../../components/Questions';
import {
  answerQuestion,
  nextQuestion,
  resetSprint
} from '../../../modules/sprint/actions';
// import { setInGameStatus } from '../../../modules/gamePlay/actions';
import SprintLogo from '../../../images/game-icons/sprint.png';

function Questions({ route }) {
  const dispatch = useDispatch();
  const selectedOption = useRef();
  const { question, count, sprint_points, answer } = useSelector(
    state => state.sprint
  );
  const [lostToTime, setLostToTime] = useState(false);
  const [timerState, setTimerState] = useState('play');

  const handleCancel = () => {
    dispatch(resetSprint());
    route('');
  };

  const handleSelectedOption = (answer, event) => {
    selectedOption.current = event.target;

    const optionItems = document.querySelectorAll('.ui.button.option');
    for (let i = 0; i < optionItems.length; i++) {
      if (selectedOption.current.innerHTML !== optionItems[i].innerHTML) {
        optionItems[i].classList.add('disabled');
        optionItems[i].disabled = true;
      }
    }

    selectedOption.current.classList.add('loading');
    setTimerState('pause');
    handleSendAnswer(answer);
  };

  const handleSendAnswer = answer => {
    dispatch(answerQuestion(answer));
  };

  const handleTimerComplete = () => {
    const optionItems = document.querySelectorAll('.ui.button.option');
    handleSendAnswer('');
    setLostToTime(true);
    console.log('timed out');

    for (let i = 0; i < optionItems.length; i++) {
      optionItems[i].classList.add('no-answer');
      optionItems[i].disabled = true;
    }
  };

  useEffect(() => {
    return () => {
      // dispatch(setInGameStatus(false));
      // console.log('closed question')
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (answer !== '') {
      if (!lostToTime) {
        selectedOption.current.classList.remove('loading');
        selectedOption.current.classList.add(answer);
        selectedOption.current.addEventListener(
          'transitionend',
          function readyForNextQuestion() {
            // next question
            setTimeout(() => {
              dispatch(nextQuestion());
            }, 600);

            selectedOption.current.removeEventListener(
              'transitionend',
              readyForNextQuestion,
              false
            );
          },
          false
        );
      } else {
        setLostToTime(false);
        setTimeout(() => {
          dispatch(nextQuestion());
        }, 600);
      }

      setTimerState('play');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer]);

  return (
    <div id="sprintQuestions">
      <QuestionsControl
        points={sprint_points}
        close={true}
        onClose={handleCancel}
        timer={question.t}
        count={count}
        timerState={timerState}
        onTimerComplete={() => handleTimerComplete()}
      />

      <QuestionItem
        key={`Q${count}`}
        image={<SprintImage />}
        content={<p>{question.question}</p>}
        raw={question.question}
        controlTimer={state => setTimerState(state)}
        points={question.points}
        counting={<QuestionCount count={count} />}
      />

      <QuestionOptions
        key={count}
        options={setupOptions(question)}
        onClick={(answer, event) => handleSelectedOption(answer, event)}
      />
    </div>
  );
}

const QuestionCount = ({ count }) => {
  return (
    <div className="d-flex">
      <h3 className="mx-auto">Question {count}</h3>
    </div>
  );
};

const SprintImage = () => {
  const Div = styled.div`
    width: 58px;
    height: 58px;
    margin-top: -62px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  `;
  return (
    <Div>
      <Image fluid src={SprintLogo} />
    </Div>
  );
};

export default Questions;
