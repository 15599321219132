import React, { useRef, useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button, Image } from 'semantic-ui-react';
import styled from 'styled-components';

import QuestionItem from '../../../../../components/Questions/QuestionItem';
import SocialMediaIcons from './SocialMediaIcons';
import { sendAnswer } from '../../../../../modules/boosters/actions';

const Item = ({ points, code, image_path, social_media_links }) => {
  const { answered } = useSelector(state => state.boosters);
  const [isWrong, setIsWrong] = useState(false);
  const dispatch = useDispatch();
  const activateButton = useRef();
  const inputRef = useRef();

  const handleAnswer = () => {
    activateButton.current.ref.current.classList.add('loading');
    activateButton.current.ref.current.classList.remove('primary');
    activateButton.current.ref.current.classList.add('disabled');
    activateButton.current.ref.current.setAttribute('disabled', true);
    inputRef.current.inputRef.current.setAttribute('disabled', true);

    dispatch(sendAnswer(code, inputRef.current.inputRef.current.value));
  };

  useLayoutEffect(() => {
    if (answered && answered === 'wrong') {
      activateButton.current.ref.current.classList.remove('loading');
      activateButton.current.ref.current.classList.add('primary');
      activateButton.current.ref.current.classList.remove('disabled');
      activateButton.current.ref.current.removeAttribute('disabled');
      setIsWrong(true);
      // inputRef.current.inputRef.current.removeAttribute('disabled');
      // inputRef.current.inputRef.current.classList.add('error');
    } else {
      setIsWrong(false);
    }
  }, [answered]);

  useLayoutEffect(() => {
    const {
      current: {
        ref: { current }
      }
    } = activateButton;

    current.setAttribute('disabled', true);
    return () => {
      current.removeAttribute('disabled');
    };
  }, []);

  return (
    <React.Fragment>
      <QuestionItem
        styled="margin-top: 57px"
        points={points}
        content={
          <QuestionContent
            key={1}
            isWrong={[isWrong, setIsWrong]}
            inputValue={inputRef}
            buttonContainer={activateButton}
            socialMediaLinks={social_media_links}
          />
        }
        counting={<QuestionCode code={code} />}
        image={
          social_media_links ? (
            <SocialMediaIcons links={social_media_links} />
          ) : (
            <QuestionImage
              image={image_path || process.env.REACT_APP_SM_IMAGE}
            />
          )
        }
      />

      <Button
        style={{ marginTop: '30px' }}
        size="huge"
        fluid
        ref={activateButton}
        onClick={() => handleAnswer()}
      >
        Done
        <div className="fill"></div>
      </Button>
    </React.Fragment>
  );
};

const QuestionContent = ({ buttonContainer, inputValue, isWrong }) => {
  const [input, setInput] = useState('');

  const handleOnChange = event => {
    setInput(event.target.value);
    isWrong[1](false);
    // console.log(inputValue);
    // inputValue.current = event.target;

    if (event.target.value.length > 0) {
      buttonContainer.current.ref.current.classList.add('primary');
      buttonContainer.current.ref.current.classList.remove('disabled');
      buttonContainer.current.ref.current.removeAttribute('disabled');
    } else {
      buttonContainer.current.ref.current.classList.remove('primary');
      buttonContainer.current.ref.current.classList.add('disabled');
      buttonContainer.current.ref.current.setAttribute('disabled', true);
    }
  };

  return (
    <>
      <p style={{ marginBottom: '24px' }}>Type in your answer and hit done</p>
      <InputWrapper>
        <Input
          fluid
          size="massive"
          placeholder="Answer"
          ref={inputValue}
          value={input}
          onChange={e => handleOnChange(e)}
        />
      </InputWrapper>

      {isWrong[0] && <Alert>You have answered incorrectly.</Alert>}
    </>
  );
};
const QuestionCode = ({ code }) => (
  <div className="d-flex">
    <h3 className="mx-auto">{code}</h3>
  </div>
);
const Alert = styled.div`
  color: #d44950;
  margin-top: 20px;
`;
const InputWrapper = styled.div`
  padding: 0 5px;

  input {
    font-weight: bold;
  }
  .ui.input > input.error {
    box-shadow: inset 0px 1px 8px rgba(174, 1, 1, 0.5);
    border-color: rgba(174, 1, 1, 0.5);
  }
`;
const QuestionImage = ({ image }) => {
  const Div = styled.div`
    width: 97px;
    height: 97px;
    margin-top: -80px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    border: 4px solid #fff;
    border-radius: 8px;
    overflow: hidden;
  `;
  return (
    <Div>
      <Image fluid src={image} />
    </Div>
  );
};

export default Item;
