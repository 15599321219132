import React from 'react';
import NumberFormat from 'react-number-format';

import { ReactComponent as Cup } from '../images/points-cup.svg';

function Points({ position, points }) {
  return (
    <div className={`page-control-points ${position}`}>
      <div className="points-wrapper">
        <div className="inner">
          <span className="icon">
            <Cup />
          </span>
          <span className="points">
            <NumberFormat
              displayType="text"
              value={points ? points : '0.00'}
              thousandSeparator=","
              suffix=" pts"
            />
          </span>
        </div>
      </div>
    </div>
  );
}

export default Points;
