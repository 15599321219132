import styled from 'styled-components';

const Status = styled.div``;
const Meta = styled.div``;
const Detail = styled.div`
  line-height: 32px;
  display: flex;
  justify-content: space-between;
  position: relative;

  &.dirty .blinking-cursor {
    display: none;
  }

  &.editing .readonly {
    display: none;
  }

  ${Status},
  ${Meta} {
    font-size: 0.875rem;
  }

  ${Status} {
    color: #00a0f5;
    cursor: crosshair;

    .ui.button {
      background-color: transparent;
      width: auto;
      color: #00a0f5;
      height: 20px;
      align-self: center;
      padding: 0;
      text-transform: none;
      font-weight: normal;
      font-size: 0.875rem;
      font-family: ProximaNovaRg, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';
    }

    svg {
      transform: rotate(180deg);
      width: 7.81px;
      height: 12.7px;
      margin-left: 12px;
    }
  }

  &.editing ${Meta} .ui.input {
    display: inline-flex;
  }

  ${Meta} .ui.dropdown {
    display: none;
  }

  &.editing ${Meta} .ui.dropdown {
    display: inline-flex;
  }

  ${Meta} .ui.input {
    position: relative;
    display: none;

    input {
      padding: 0;
      border-radius: 0;
      background-color: transparent;
      border-width: 0 0 1px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      color: #fff;
      box-sizing: border-box;
      font-size: 0.875rem;
      position: relative;

      &::placeholder {
        color: rgba(191, 191, 191, 0.87);
      }

      &:focus {
        outline: none;
        border-width: 0 0 1px 0;
      }

      &:focus + .underline {
        transform: scale(1);
      }
    }

    .underline {
      content: '';
      background-color: dodgerblue;
      display: inline-block;
      height: 2px;
      position: absolute;
      left: 0;
      bottom: 0;
      transform: scale(0, 1);
      -webkit-transition: all 0.5s linear;
      transition: all 0.5s linear;
      width: 100%;
    }
  }
`;

export { Detail, Meta, Status };
