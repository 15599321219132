import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Popeye from '../../../components/Popeye';
import GameCompleted from '../../../components/GameCompleted';
import { setInGameStatus } from '../../../modules/gamePlay/actions';
import { resetSprint } from '../../../modules/sprint/actions';

function Completed({
  points,
  username,
  handleOnConfirm,
  correct,
  count,
  route
}) {
  const dispatch = useDispatch();
  const {
    player: {
      info: { unanswered_trigger_count }
    }
  } = useSelector(state => state);

  useEffect(() => {
    dispatch(setInGameStatus(false));
    // eslint-disable-next-line
  }, []);

  return (
    <Popeye show={true} key={1}>
      <GameCompleted
        points={points}
        content={
          <Content
            {...{
              username,
              count,
              correct,
              sprintCount: unanswered_trigger_count
            }}
          />
        }
        onConfirm={handleOnConfirm}
        enablePlayAgain={unanswered_trigger_count > 0}
        onPlayAgain={() => {
          dispatch(resetSprint());
          route('');
        }}
      />
    </Popeye>
  );
}

const Content = ({ username, correct, count, sprintCount }) => {
  let perc = (correct / count) * 100;
  console.log(perc + '%');
  let convo = '';
  switch (true) {
    case 0:
      convo = 'Well..., you made it to the end';
      break;
    case perc > 0 && perc <= 20:
      convo = `${username}, you are something else`;
      break;
    case perc > 20 && perc <= 45:
      convo = `You'll get them next ${username}!`;
      break;
    case perc > 45 && perc <= 55:
      convo = `Good try ${username}!`;
      break;
    default:
      convo = `Well played ${username}!`;
      break;
  }
  return (
    <p>
      {convo} <br />
      You got{' '}
      <strong>
        {correct}/{count}
      </strong>{' '}
      correct.
      <br />
      <span className="highlight">{sprintCount}</span> questions left.
    </p>
  );
};

export default Completed;

// 0 1px 2px #fff, /*bottom external highlight*/
// 0 -1px 1px #666, /*top external shadow*/
// inset 0 -1px 1px rgba(0,0,0,0.5), /*bottom internal shadow*/
// inset 0 1px 1px rgba(255,255,255,0.8); /*top internal highlight*/
