export const QUESTION = '[sprint] Get trigger question';
export const QUESTION_FAILED = '[sprint] questions failed';
export const QUESTION_RECEIVED = '[sprint] received question';
export const STORE_QUESTION = '[sprint] store question';

export const ANSWER = '[sprint] Answered Question';
export const ANSWER_RECEIVED = '[sprint] Answer received';
export const ANSWER_FAILED = '[sprint] Validation failed';
export const CORRECT_ANSWER = '[sprint] Answered right';
export const WRONG_ANSWER = '[sprint] Answered wrong';
export const UPDATE_POINTS = '[sprint] Update points';

export const NEXT_QUESTION = '[sprint] Set next question';
export const END_SPRINT = '[sprint] Sprint completed';
export const RESET = '[sprint] Reset sprint';
export const EXHAUSTED = '[sprint] Completed daily sprint';
export const REDUCE_SPRINT_COUNT = '[sprint] update sprint count';
export const SET_QUESTION_STATUS = '[sprint] set question status';

export const getQuestion = () => ({
  type: QUESTION
});

export const answerQuestion = answer => ({
  type: ANSWER,
  answer
});

export const updatePoints = points => ({
  type: UPDATE_POINTS,
  points
});

export const nextQuestion = () => ({
  type: NEXT_QUESTION
});

export const storeQuestion = payload => ({
  type: STORE_QUESTION,
  payload
});

export const resetSprint = () => ({
  type: RESET
});

export const exhausted = () => ({
  type: EXHAUSTED
});

export const reduceSprintCount = count => ({
  type: REDUCE_SPRINT_COUNT,
  count
});

export const setQuestionStatus = status => ({
  type: SET_QUESTION_STATUS,
  status
});
