import React from 'react';
import { useSelector } from 'react-redux';
import { sexifyIntl } from '../../../util/formatPhoneNumber';

import GameItem from './GameItem';
import GameItemTriv5 from './GameItemTriv5';
import socialMedia from '../../../images/game-icons/socialMedia.png';
import triv5 from '../../../images/game-icons/triv5.png';

const GameListings = ({ loadContent }) => {
  const {
    token,
    info: { msisdn }
  } = useSelector(state => state.player);

  const gameList = [
    {
      name: 'Social Media',
      desc: (
        <p>
          Get points by solving puzzles <br />
          from our social media pages
        </p>
      ),
      icon: socialMedia
    },
    {
      name: 'Triv 5',
      desc: (
        <p>
          Get points and win airtime instantly.
          <br />
          Answer 5 questions in 50 secs
        </p>
      ),
      icon: triv5,
      external: true,
      url: `https://triv5.brainwavegh.com/?TOKEN=${token}&MSISDN=${sexifyIntl(
        msisdn
      )}`
    }
  ];

  return (
    <div className="game-listings mt-4 pt-1">
      {gameList.map((item, i) =>
        item.name.replace(/\s+/g, '').toLowerCase() === 'triv5' ? (
          <GameItemTriv5 {...{ key: i, item }} />
        ) : (
          <GameItem {...{ key: i, item, loadContent }} />
        )
      )}
    </div>
  );
};

export default GameListings;
