export const GET_QOD = '[qod] Get QOD';
export const GET_QOD_SUCCESS = '[qod] Received QOD';
export const GET_QOD_ERROR = '[qod] QOD failed';
export const STORE_QUESTION = '[qod] Store question';
export const NOTIFY = '[qod] Notification';
export const HIDE_NOTIFY = '[qod] Hide notification';
export const PROVE_ANSWER = '[qod] Prove answer';
export const PROVE_ANSWER_RECEIVED = '[qod] Received proof';
export const PROVE_ANSWER_FAILED = '[qod] Prove answer failed';
export const CORRECT_ANSWER = '[qod] Answered correctly';
export const WRONG_ANSWER = '[qod] Answered wrong';
export const NEXT_ATTEMPT = '[qod] Next attempt';
export const QOD_COMPLETED = "[qod] Completed today's QOD";
export const QOD_RESET = '[qod] Reset';

export const getQOD = () => ({
  type: GET_QOD
});

export const storeQuestion = question => ({
  type: STORE_QUESTION,
  question
});

export const proveAnswer = answer => ({
  type: PROVE_ANSWER,
  answer
});

export const nextAttempt = () => ({
  type: NEXT_ATTEMPT
});

export const completedQOD = () => ({
  type: QOD_COMPLETED
});

export const reset = () => ({
  type: QOD_RESET
});
