import { useEffect } from 'react';
import ReactDOM from 'react-dom';

const Popeye = ({ children, show, align, ...props }) => {
  const modalRoot = document.getElementById('root');
  const el = document.createElement('div');
  let popeyeClass = ['ui', 'dimmer', 'popeye', ...[props.class]];

  useEffect(() => {
    if (show) popeyeClass.push('active');
    if (align) popeyeClass.push(`${align} aligned`);
    el.setAttribute('class', popeyeClass.join(' '));
    modalRoot.parentNode.insertBefore(el, modalRoot.nextSibling);
    return () => {
      document.body.removeChild(el);
    };
  }, [modalRoot, el, popeyeClass, show, align]);

  return ReactDOM.createPortal(children, el);
};

export default Popeye;
