import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'semantic-ui-react';

import { hideNotify } from '../modules/ui/actions';

const BillPlayerModal = ({ onBill, trigger, onBillSuccess }) => {
  const dispatch = useDispatch();
  const { status, message, alert } = useSelector(state => state.ui.notify);
  const loadingSelector = useSelector(state => state.ui.billing);
  const billed = useSelector(state => state.player.info.is_billed);

  const [buttonText, setButtonText] = useState('Renew');
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState('bill');
  const [feedbackTransition, setFeedbackTransition] = useState('');

  const handleOnMount = () => {
    trigger.current.classList.remove('loading');
  };

  const handleOnClick = (action, event) => {
    setFeedbackTransition('');
    switch (action) {
      case 'bill':
        setLoading(true);
        onBill();
        break;

      case 'cancel':
        dispatch(hideNotify());
        break;

      case 'complete':
        onBillSuccess();
        dispatch(hideNotify());
        break;

      default:
        dispatch(hideNotify());
        break;
    }
  };

  useEffect(() => {
    if (message !== '' || loadingSelector !== false) {
      if (message.includes('valid Vodafone number')) {
        setAction('cancel');
        setButtonText('Okay');
        setFeedbackTransition('pulse');
      }
      if (message.includes('try again')) {
        if (message.includes('sufficient airtime')) {
          setAction('cancel');
          setButtonText('Okay');
        } else {
          setAction('retry');
          setButtonText('Try Again');
        }
      }
      setFeedbackTransition('pulse');
    } else {
      setAction('bill');
      setLoading(false);
      setButtonText('Renew');
    }
    return () => {
      setAction('bill');
      setButtonText('Renew');
      setLoading(false);
      setFeedbackTransition('');
    };
  }, [message, loadingSelector]);

  useEffect(() => {
    if (billed) {
      setLoading(false);
      setButtonText("Let's Go");
      setAction('complete');
    }
  }, [billed]);

  return (
    <Modal
      open={status}
      onMount={handleOnMount}
      className={`notification ${alert}`}
    >
      <Modal.Header>
        <h1>{billed ? 'Thank you' : 'Ahh snap!'}</h1>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description className={`transition ${feedbackTransition}`}>
          {message ? (
            <p>{message}</p>
          ) : billed ? (
            <p>
              Your subscription renewal has <br />
              been successful.
            </p>
          ) : (
            <p>
              Your subscription renewal failed <br />
              due to insufficient balance. <br />
              Renew now at 0.27p
            </p>
          )}
        </Modal.Description>
      </Modal.Content>

      <Modal.Actions>
        <Button
          primary={!loading}
          loading={loading}
          disabled={loading}
          size="huge"
          onClick={event => handleOnClick(action, event)}
        >
          {buttonText}
          <span className="fill"></span>
        </Button>
        {!billed && (
          <Button
            style={{ width: '100%' }}
            className="noButton mt-4"
            onClick={() => handleOnClick('cancel')}
          >
            Not just yet
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default BillPlayerModal;
