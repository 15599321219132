export const UPDATE_PLAYER = '[player] Update player';
export const UPDATE_PLAYER_POINTS = '[player] Update points';
export const UPDATE_USERNAME = '[player] Update username';
export const UPDATE_PLAYER_RANK = '[player] Update rank';
export const UPDATE_IMAGE = '[player] update profile image';
export const CHANGE_PLAYER_INFO = '[player] Change player info';
export const CHANGE_PLAYER_INFO_RECEIVED = '[player] Change player received';
export const CHANGE_PLAYER_INFO_FAILED = '[player] Change player info failed';
export const GET_PLAYER = '[player] Get player info';
export const GET_PLAYER_SUCCESS = '[player] Received player info';
export const GET_PLAYER_ERROR = '[player] Get player failed';
export const STORE_ISD = '[player] Store Unique ID';
export const UPDATE_ACHIEVEMENT = '[player] Update player achievement';
export const BILL_PLAYER = '[player] Bill subscription';
export const BILL_SUCCESS = '[player] Bill successful';
export const BILL_ERROR = '[player] Bill failed';
export const CHANGE_USERNAME = '[player] Change username';
export const CHANGE_USERNAME_RECEIVED = '[player] Change username received';
export const CHANGE_USERNAME_FAILED = '[player] Change username failed';
export const UPDATE_QOD_STATUS = '[player] Answered QOD';
export const UPLOAD_PROFILE_IMAGE = '[player] Upload profile Image';
export const UPLOAD_PROFILE_IMAGE_RECEIVED = '[player] Profile image uploaded';
export const UPLOAD_PROFILE_IMAGE_FAILED =
  '[player] Profile image upload failed';
export const PLAYER_UNAUTHORISED = '[player] Unauthorised';
export const UPDATE_RISKIT_KEYS = '[player] Update the key count';
export const LOG_UPDATED_FIELD = '[player] Log updated field';
export const UPDATE_QOD_AVAILABILITY = '[player] QOD is available';
export const UPDATE_QOD_CHANCES = '[qod] Update QOD chances';

export const getPlayer = (msisdn, token) => ({
  type: GET_PLAYER
});

export const updatePlayer = (info, token) => ({
  type: UPDATE_PLAYER,
  meta: { info, token }
});

export const updatePlayerPoints = points => ({
  type: UPDATE_PLAYER_POINTS,
  points
});

export const updatePlayerRank = rank => ({
  type: UPDATE_PLAYER_RANK,
  rank
});

export const updatePlayerInfo = (info, value) => ({
  type: CHANGE_PLAYER_INFO,
  value,
  meta: { info }
});

export const updateUsername = username => ({
  type: UPDATE_USERNAME,
  username
});

export const changeUsername = username => ({
  type: CHANGE_USERNAME,
  username
});

export const updateImage = path => ({
  type: UPDATE_IMAGE,
  path
});

export const storeISD = ISD => ({
  type: STORE_ISD,
  ISD
});

export const playerUnauthorised = () => ({
  type: PLAYER_UNAUTHORISED
});

export const updateAchievement = payload => ({
  type: UPDATE_ACHIEVEMENT,
  payload
});

export const billPlayer = () => ({
  type: BILL_PLAYER
});

export const updateQODStatus = status => ({
  type: UPDATE_QOD_STATUS,
  status
});

export const uploadProfileImage = imgBase64 => ({
  type: UPLOAD_PROFILE_IMAGE,
  meta: {
    imgBase64
  }
});

export const updateRiskitKeysCount = () => ({
  type: UPDATE_RISKIT_KEYS
});

export const logUpdateField = (field, value) => ({
  type: LOG_UPDATED_FIELD,
  field,
  value
});
