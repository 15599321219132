export const API_REQUEST = '[api] Request';
export const API_FEEDBACK = '[api] Feedback';
export const WS_CONNECT = '[app] Websocket connecting';
export const WS_CONNECTED = '[app] Websocket connected';
export const WS_RECONNECT = '[app] Websocket reconnection';
export const WS_DISCONNECTED = '[app] Websocket disconnected';
export const SYSTEM_ERROR = '[app] System error';
export const UPDATE_MANAGER = '[app] Connect update manager';
export const SUBSCRIBE_TOPIC = '[app] Subscribe to topic';
export const SW_INIT = '[app] Service worker initiated';
export const SW_UPDATE = '[app] Service worker update';
export const SW_UPDATING = '[app] Service worker updating';
export const SW_UPDATED = '[app] Service worker updated';

export const wsConnect = () => ({
  type: WS_CONNECT
});

export const wsConnected = socket => ({
  type: WS_CONNECTED,
  socket
});

export const wsDisconnected = () => ({
  type: WS_DISCONNECTED
});

export const apiRequest = (method, url, body, onSuccess, onError) => ({
  type: API_REQUEST,
  meta: { method, url, body, onSuccess, onError }
});

export const apiFeedback = payload => ({
  type: API_FEEDBACK,
  payload
});

export const systemError = message => ({
  type: SYSTEM_ERROR,
  message
});

export const updateManager = () => ({
  type: UPDATE_MANAGER
});

export const subscribeTopic = (topic, onReceive, onFailure) => ({
  type: SUBSCRIBE_TOPIC,
  topic,
  meta: { onReceive, onFailure }
});

export const swUpdating = () => ({
  type: SW_UPDATING
});

export const swUpdated = () => ({
  type: SW_UPDATED
});
