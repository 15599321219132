export const LEADERBOARD = '[game] Get leaderboard';
export const LEADERBOARD_RECEIVED = '[game] Leaderboard received';
export const LEADERBOARD_FAILED = '[game] Leaderboard failed';
export const STORE_LEADERBOARD = '[game] Store leaderboard';
export const UPDATE_USERNAME_MODAL = '[game] User modified username';
export const GET_ACHIEVEMENTS = '[game] Get achievements';
export const GET_ACHIEVEMENTS_RECEIVED = '[game] Received achievements';
export const GET_ACHIEVEMENTS_FAILED = '[game] Achievements failed';
export const STORE_ACHIEVEMENTS = '[game] Store achievements';
export const ACHIEVEMENT_UNLOCKED = '[game] Achievement unlocked';
export const GET_CAMPAIGN = '[game] Get campaign';
export const GET_CAMPAIGN_RECEIVED = '[game] Received campaign';
export const GET_CAMPAIGN_FAILED = '[game] Campaign failed';
export const STORE_CAMPAIGN = '[game] Store campaign';
export const TOGGLE_NOTIFICATIONS = '[settings] Toggle Notification';
export const TOGGLE_IN_GAME_MUSIC = '[settings] Toggle in game music';
export const TOGGLE_SOUND_EFFECT = '[settings] Toggle sound effects';
export const UPDATE_CAMPAIGN_IMAGE = '[game] Update campaign image';
export const UPDATE_CAMPAIGN_COUNTDOWN = '[game] Update campaign countdown';
export const LOGOUT = '[game] Logout';
export const CAMPAIGN_STATUS = '[game] Change campaign status';
export const OPT_IN = '[game] Opt in';
export const OPT_IN_SUCCESS = '[game] Opt in successful';
export const OPT_IN_FAILURE = '[game] Opt in failed';
export const NEW_CAMPAIGN_OPT = '[game] New campaign opt_in';
export const SET_TOAST = '[game] Set toast';
export const SHOW_TOASTS = '[game] Show all toasts';
export const IN_GAME_STATUS = '[game] Set in game status';
export const CHECK_QUESTION_FORMAT = '[game] Check question format';
export const QUESTION_FORMAT = '[game] Set question format';
export const IMAGE_URL_REGEX = /\b(https?:\/\/\S+(?:png|jpe?g|gif)\S*)\b/gi;
export const SET_NOTIFICATION = '[settings] Set Notification status';

export const getLeaderboard = () => ({
  type: LEADERBOARD
});

export const storeLeaderboard = leaderboard => ({
  type: STORE_LEADERBOARD,
  leaderboard
});

export const getAchievements = () => ({
  type: GET_ACHIEVEMENTS
});

export const storeAchievements = payload => ({
  type: STORE_ACHIEVEMENTS,
  payload
});

export const achievementUnlocked = achievement => ({
  type: ACHIEVEMENT_UNLOCKED,
  achievement
});

export const toggleShowUsernameModal = state => ({
  type: UPDATE_USERNAME_MODAL,
  state
});

export const toggleNotification = (name, toggle) => ({
  type: TOGGLE_NOTIFICATIONS,
  toggle,
  meta: { name }
});

export const setNotification = (name, toggle) => ({
  type: SET_NOTIFICATION,
  toggle,
  meta: { name }
});

export const toggleInGameMusic = toggle => ({
  type: TOGGLE_IN_GAME_MUSIC,
  toggle
});

export const toggleSoundEffect = toggle => ({
  type: TOGGLE_SOUND_EFFECT,
  toggle
});

export const logout = () => ({
  type: LOGOUT
});

export const getCampaign = () => ({
  type: GET_CAMPAIGN
});

export const storeCampaign = payload => ({
  type: STORE_CAMPAIGN,
  payload
});

export const campaignStatus = status => ({
  type: CAMPAIGN_STATUS,
  status
});

export const updateImage = path => ({
  type: UPDATE_CAMPAIGN_IMAGE,
  path
});

export const updateCountdown = time => ({
  type: UPDATE_CAMPAIGN_COUNTDOWN,
  time
});

export const newCampaignOptIn = payload => ({
  type: NEW_CAMPAIGN_OPT,
  payload
});

export const optIn = status => ({
  type: OPT_IN,
  status
});

export const setToast = (key, message) => ({
  type: SET_TOAST,
  meta: { key },
  message
});

export const setInGameStatus = status => ({
  type: IN_GAME_STATUS,
  status
});

export const showToasts = () => ({
  type: SHOW_TOASTS
});

export const checkQuestionFormat = question => ({
  type: CHECK_QUESTION_FORMAT,
  question
});

export const setQuestionFormat = format => ({
  type: QUESTION_FORMAT,
  format
});
