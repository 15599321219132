import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ButtonSVG } from '../images/back-button.svg';
import { ReactComponent as CloseSVG } from '../images/close.svg';

function BackButton({ onClick, pageName, close }) {
  return (
    <Button className="d-flex" onClick={onClick}>
      {close ? <CloseSVG /> : <ButtonSVG />}
      {pageName && <Nav>{pageName}</Nav>}
    </Button>
  );
}

const Button = styled.div``;

const Nav = styled.div`
  color: #fff;
  font-size: 1.125em;
  font-weight: bold;
  margin-left: 25px;
  margin-top: -3px;
`;

export default BackButton;
