import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Popeye from '../../../../../components/Popeye';
import GameCompleted from '../../../../../components/GameCompleted';
import { prepareForNextCode } from '../../../../../modules/boosters/actions';

function Completed({ points, handleOnConfirm, show, target }) {
  const { username } = useSelector(state => state.player.info.username);
  const dispatch = useDispatch();

  const handleConfirm = () => {
    target.parentNode.removeChild(target);
    dispatch(prepareForNextCode());
    handleOnConfirm();
  };

  return (
    <Popeye show={show}>
      <GameCompleted
        points={points}
        content={<Content {...{ username }} />}
        onConfirm={handleConfirm}
      />
    </Popeye>
  );
}

const Content = ({ username }) => (
  <p>Well done {username}! You answered correctly</p>
);

export default Completed;
