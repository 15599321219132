export const GET_RISKIT = '[riskit] Get riskit question';
export const GET_RISKIT_SUCCESS = '[riskit] Received riskit question';
export const GET_RISKIT_ERROR = '[riskit] Riskit failed';
export const NO_QUESTION = '[riskit] No riskIt question';
export const STORE_QUESTION = '[riskit] Store question';
export const PROVE_RISKIT = '[riskit] Validate Riskit';
export const PROVE_RISKIT_SUCCESS = '[riskit] Validation success';
export const PROVE_RISKIT_ERROR = '[riskit] Validation failed';
export const RISKIT_PROVEN = '[riskit] Proven';
export const RISKIT_NOT_PROVEN = '[riskit] Not proven';
export const RESET = '[riskit] Reset';

export const getRiskIt = () => ({
  type: GET_RISKIT
});

export const proveRiskit = answer => ({
  type: PROVE_RISKIT,
  answer
});

export const provedRiskit = () => ({
  type: RISKIT_PROVEN
});

export const notProveRiskit = () => ({
  type: RISKIT_NOT_PROVEN
});

export const storeQuestion = question => ({
  type: STORE_QUESTION,
  question
});

export const reset = () => ({
  type: RESET
});
