import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Ready from './Sprint/Ready';
import Questions from './Sprint/Questions';
import Completed from './Sprint/Completed';
import NoMoreQuestions from './Sprint/NoMoreQuestions';

import { resetSprint } from '../../modules/sprint/actions';
import { setInGameStatus } from '../../modules/gamePlay/actions';

function Sprint({ history }) {
  const dispatch = useDispatch();
  const [activeComponent, setActiveComponent] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const { current_points, username, unanswered_trigger_count } = useSelector(
    state => state.player.info
  );
  const {
    count,
    completed,
    correct_count,
    sprint_points,
    noMoreQuestions
  } = useSelector(state => state.sprint);

  const route = component => {
    console.log(component);
    setActiveComponent(component);
  };

  const handleOnBack = () => {
    history.push('/arcade');
  };

  const handleCloseAlert = () => {
    dispatch(resetSprint());
  };

  useEffect(() => {
    if (count === 1) {
      route('questions');
      dispatch(setInGameStatus(true));
    }
    // eslint-disable-next-line
  }, [count]);

  useEffect(() => {
    if (completed) {
      route('completed');
    }

    return () => {
      if (completed) {
        dispatch(resetSprint());
      }
    };
  }, [completed, dispatch]);

  useEffect(() => {
    if (noMoreQuestions || unanswered_trigger_count === 0) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [noMoreQuestions, unanswered_trigger_count]);

  return (
    <div className="container">
      {(() => {
        switch (activeComponent) {
          case 'questions':
            return <Questions route={component => route(component)} />;

          case 'completed':
            return (
              <Completed
                {...{
                  username,
                  count,
                  points: sprint_points,
                  handleOnConfirm: handleOnBack,
                  correct: correct_count,
                  route: component => route(component)
                }}
              />
            );

          default:
            return [
              <Ready
                key={0}
                points={current_points}
                onBack={handleOnBack}
                route={component => route(component)}
              />,
              <NoMoreQuestions
                key={1}
                open={showAlert}
                onClose={() => {
                  if (unanswered_trigger_count === 0) {
                    history.push('/arcade');
                  } else {
                    handleCloseAlert();
                  }
                }}
              />
            ];
        }
      })()}
    </div>
  );
}

export default Sprint;
