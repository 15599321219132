// eslint-disable-next-line
import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

// const checkCookie = () => null;
const PublicRoute = ({ component: Component, player, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        !player.token ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};

const mapStateToProps = state => ({
  player: state.player
});

export default connect(mapStateToProps)(PublicRoute);
