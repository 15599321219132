import Paho from 'paho-mqtt';
// import { toast } from 'react-toastify';

import {
  WS_CONNECT,
  API_REQUEST,
  UPDATE_MANAGER,
  wsConnected,
  apiFeedback,
  systemError,
  subscribeTopic
} from './actions';
import {
  getPlayer,
  playerUnauthorised,
  updatePlayerRank,
  updatePlayer,
  UPDATE_QOD_AVAILABILITY
} from '../player/actions';
import { ONBOARD_COMPLETED } from '../onboard/actions';
import { achievementUnlocked, setToast } from '../gamePlay/actions';
import { showCover, hideCover } from '../ui/actions';
import { showSpinner, hideSpinner } from '../ui/actions';

const onConnectionLost = responseObject => {
  if (responseObject.errorCode !== 0) {
    console.log(
      '%c%s',
      'color:red',
      `[socket] connection lost ${responseObject.errorMessage}`
    );
  }
};

const onMessageDelivered = response => {
  // console.log('%c Debug: ', 'color:brown', response.payloadString);
};
const onMessageArrived = (dispatch, getState) => response => {
  const {
    app: { topics }
  } = getState();
  const receivedTopic = response.topic.split('/');
  const { status, message, data } = JSON.parse(response.payloadString);

  if (process.env.NODE_ENV === 'development') {
    console.log(JSON.parse(response.payloadString));
    console.log(`Topic:     ${response.destinationName}`);
  }

  if ('getCurrentCampaign' === receivedTopic[2]) {
    const {
      app: {
        ws: { socket }
      }
    } = getState();
    socket.unsubscribe(`${response.destinationName}`);
  }
  // console.log(`QoS:       ${response.qos}`);
  // console.log(`Retained:  ${response.retained}`);
  // Read Only, set if message might be a duplicate sent from broker
  // console.log(`Duplicate: ${response.duplicate}`);
  const isReceivedTopic =
    receivedTopic[0] === 'test' ? receivedTopic[3] : receivedTopic[2];
  switch (true) {
    case isReceivedTopic in topics:
      const { onReceive, onFailure } = topics[isReceivedTopic];

      dispatch(apiFeedback({ status, message, data }));

      if (status === true) {
        dispatch(onReceive);
      } else if (status === 'error') {
        if (message === 'Authentication Failed') {
          dispatch(playerUnauthorised());
        } else {
          dispatch(systemError(message));
        }
      } else {
        dispatch(onFailure);
      }
      // TODO: remove topic from store
      dispatch(hideSpinner());
      break;

    default:
      if (process.env.NODE_ENV === 'development')
        console.log(
          '%c[socket] push notification: ',
          'color:teal',
          response.payloadString
        );
      const payload = JSON.parse(response.payloadString);
      console.log(payload);
      switch (true) {
        case payload.rankChange !== undefined:
          const { newRank, direction, culprit } = payload.rankChange;
          const message =
            direction === 'up'
              ? `You have moved up to a new rank ${newRank}`
              : `You have been overtaken by ${culprit}, you are now ${newRank}`;
          dispatch(updatePlayerRank(newRank));
          // toast('You have a new rank.', { autoClose: 7000 });
          dispatch(setToast('rank', message));
          break;

        case payload.achievement !== undefined:
          dispatch(achievementUnlocked(payload.achievement));
          break;

        case payload.riskit !== undefined:
          console.log('got riskit');
          break;

        case payload.QOD !== undefined:
          dispatch({ type: UPDATE_QOD_AVAILABILITY });
          break;

        case payload.campaignEnd !== undefined:
          console.log('campaign ended');
          break;

        case payload.subscribed !== undefined:
          dispatch(updatePlayer(payload.data.player_info, payload.data.token));
          dispatch({ type: ONBOARD_COMPLETED });
          break;

        default:
          break;
      }
      break;
  }
};

export const websocketFlow = ({ dispatch, getState }) => next => action => {
  next(action);

  if (WS_CONNECT === action.type) {
    dispatch(showCover());

    try {
      const cid = (Date.parse(Date()) / 1000).toString();
      const client = new Paho.Client(
        process.env.REACT_APP_WS_DOMAIN,
        parseInt(process.env.REACT_APP_WS_PORT),
        cid
      );
      client.onConnectionLost = onConnectionLost;
      client.onMessageArrived = onMessageArrived(dispatch, getState);
      client.onMessageDelivered = onMessageDelivered;

      client.connect({
        userName: process.env.REACT_APP_WS_USERNAME,
        password: process.env.REACT_APP_WS_PASSWORD,
        useSSL: true,
        reconnect: true,
        keepAliveInterval: 1800,
        onSuccess: () => {
          const {
            player: {
              info: { msisdn }
            }
          } = getState();

          dispatch(wsConnected(client));

          if (msisdn !== '') {
            dispatch(getPlayer());
          } else {
            dispatch(hideCover());
          }
        },
        onFailure: () => {
          // TODO: show error message
        }
      });
    } catch (error) {
      // TODO: show error message
      console.log(`%c ${error}`, 'color:red');
    }
  }
};

export const updateManagerFlow = ({ getState }) => next => action => {
  next(action);

  if (UPDATE_MANAGER === action.type) {
    const {
      player: { ISD },
      app: {
        ws: { socket }
      }
    } = getState();
    const onBoardISD = getState().onBoard.ISD;
    try {
      if (Object.entries(socket).length > 0) {
        const reff = action.useOnboard ? onBoardISD : ISD;
        socket.subscribe(`receive/notifications/${reff}`, {
          onSuccess: () =>
            console.log('%cPush notifications awaits:', 'color:green')
        });
      }
    } catch (error) {
      // TODO: show error message
      console.log(`%c ${error}`, 'color:red');
    }
  }
};

export const apiRequestFlow = ({ dispatch, getState }) => next => action => {
  next(action);

  if (API_REQUEST === action.type) {
    const {
      app: {
        ws: { socket }
      }
    } = getState();
    const { method, url, body, onSuccess, onError } = action.meta;
    let payload = body || 'web';

    dispatch(showSpinner());

    try {
      let prefix = '';
      const timestamp = (Date.parse(Date()) / 1000).toString();
      // if (process.env.NODE_ENV === 'development') prefix = 'test/';
      socket.subscribe(`${prefix}receive/${timestamp}/${url}`);
      let message = new Paho.Message(payload);
      message.topic = `${prefix}${method}/${timestamp}/${url}`;
      message.retained = false;
      message.qos = 0;
      socket.send(message);

      let topic = url.substring(0, url.indexOf('/'));
      topic = topic === '' ? url : topic;
      dispatch(subscribeTopic(topic, onSuccess, onError));
    } catch (error) {
      dispatch(showSpinner());
      console.log('[api_error]: ', error);
    }
  }
};

const appMdl = [websocketFlow, apiRequestFlow, updateManagerFlow];

export default appMdl;
