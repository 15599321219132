import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Image } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

import Countdown from '../components/useCountdown';
import playBtnImage from '../images/playBtn.png';
import RiskOn from '../images/riskit-on.png';
import RiskOff from '../images/riskit-off.png';
import { ReactComponent as AchievementsIcon } from '../images/award.svg';
import { ReactComponent as LeaderboardIcon } from '../images/leaderboard-Icon.svg';
import { ReactComponent as SettingsIcon } from '../images/settings-Icon.svg';
import VerifiedModal from '../components/verifiedModal';
import numberSuffixer from '../util/numberSuffixer';
import { getCampaign } from '../modules/gamePlay/actions';

const RiskItKey = ({ image }) => {
  let src = image === 'on' ? RiskOn : RiskOff;
  return (
    <div className="key-item">
      <Image fluid src={src} />
    </div>
  );
};

const Welcome = ({ player: { info }, gamePlay, history }) => {
  const {
    current_points,
    current_rank,
    username_changed,
    username,
    riskit_keys
  } = info;
  const {
    settings: { showUsernameModal, riskItBaskets },
    campaign
  } = gamePlay;
  const [showChangeUsername, setShowChangeUsername] = useState(false);
  let onKeyCount = riskItBaskets;
  const dispatch = useDispatch();

  useEffect(() => {
    if (username_changed === false && showUsernameModal === true) {
      setShowChangeUsername(true);
    } else {
      setShowChangeUsername(false);
    }
  }, [username_changed, showUsernameModal]);

  useEffect(() => {
    return () => {
      // dispatch(updateCountDown())
    };
  }, []);

  const startCampaign = ({ completed }) => {
    if (completed) {
      console.log('completed');
      dispatch(getCampaign());
    }
  };

  return (
    <div className="container d-flex flex-column h-100">
      <div className="row justify-content-between mb-auto player-meta">
        <div className="col-7">
          <div className="profile-summary">
            <div
              className="image"
              onClick={() => history.push('/profile')}
              style={{ backgroundImage: `url(${info.image_path})` }}
            >
              <div className="circle__spin">
                <svg viewBox="0 0 200 200">
                  <circle cx="100" cy="100" r="80"></circle>
                </svg>
              </div>
            </div>
            <div className="blue__box">
              <span className="rank">
                {current_rank ? numberSuffixer(current_rank) : 'not ranked'}
              </span>
              <span className="points">
                <NumberFormat
                  displayType="text"
                  value={current_points || '0.00'}
                  thousandSeparator=","
                  suffix=" pts"
                />
              </span>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="riskIt">
            <div className="blue__box">
              <div className="keys">
                {[...Array(riskItBaskets)].map((x, i) => {
                  const value = riskit_keys < onKeyCount ? 'off' : 'on';
                  onKeyCount--;
                  return <RiskItKey key={i} image={value} />;
                })}
              </div>
              <div className="desc">
                <div className="blue__box">Risk It keys</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center position-relative">
        <div className="adv__box w-100 align-self-center">
          <div className="inner d-flex justify-content-center">
            <div className="object">
              <Image src={campaign.meta.image_path} />
            </div>
          </div>
        </div>

        {!campaign.running && (
          <div className="campaignCountDownWrapper">
            <Countdown
              timer={campaign.meta.next_campaign_start}
              onComplete={startCampaign}
            />
          </div>
        )}
      </div>

      <div className="row justify-content-center"></div>

      <div className="row justify-content-around mt-auto pb-4">
        <div className="playButton mb-5">
          <div className="playButtonWrapper">
            <Link to="/arcade">
              <Image fluid src={playBtnImage} />
            </Link>
          </div>
        </div>

        <ul className="__nav align-self-end justify-content-around">
          <li onClick={() => history.push('/achievements')}>
            <div className="nav-item">
              <StyledAchievementsIcon />
            </div>
          </li>

          <li onClick={() => history.push('/leaderboard')}>
            <div className="nav-item">
              <LeaderboardIcon />
            </div>
          </li>

          <li onClick={() => history.push('/settings')}>
            <div className="nav-item">
              <SettingsIcon />
            </div>
          </li>
        </ul>
      </div>

      <VerifiedModal open={showChangeUsername} username={username} />
    </div>
  );
};

const StyledAchievementsIcon = styled(AchievementsIcon)`
  path {
    fill: #fff;
  }
`;

const mapStateToProps = state => ({
  player: state.player,
  gamePlay: state.gamePlay
});

export default connect(mapStateToProps)(Welcome);
