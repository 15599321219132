import React, { useRef } from 'react';
import { Image } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';

import GetReady from '../../../components/GetReady';
import BackButton from '../../../components/BackButton';
import Points from '../../../components/Points';
import SprintIcon from '../../../images/game-icons/sprint.png';
import { getQuestion } from '../../../modules/sprint/actions';

function Ready({ points, onBack }) {
  const letsGo = useRef();
  const dispatch = useDispatch();

  const handleOnClick = event => {
    const letsGoButton = event.currentTarget;
    letsGoButton.classList.add('loading', 'disabled');
    letsGoButton.classList.remove('primary');
    letsGoButton.disabled = true;
    letsGo.current = letsGoButton;
    dispatch(getQuestion());
  };

  return (
    <GetReady
      show={true}
      pageControl={<Controls goBack={onBack} points={points} />}
      icon={<Icon />}
      content={<Description />}
      okAction={{
        text: "Let's Go",
        onClick: event => handleOnClick(event)
      }}
      noAction={{
        text: 'Chicken Out',
        onClick: onBack
      }}
    />
  );
}

const Icon = () => (
  <div style={{ width: '130px', marginTop: '75px', marginBottom: '25px' }}>
    <Image fluid src={SprintIcon} />
  </div>
);
const Controls = ({ goBack, points }) => {
  return (
    <React.Fragment>
      <div className="col-auto mr-auto">
        <BackButton close={true} onClick={() => goBack()} />
      </div>
      <div className="col ml-auto">
        <Points position="right" points={points} />
      </div>
    </React.Fragment>
  );
};
const Description = () => (
  <p style={{ maxWidth: '240px', textAlign: 'center' }}>
    10 hot questions coming your way. Are you ready?
  </p>
);

export default Ready;
