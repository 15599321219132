import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Image, Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';

import BackButton from '../components/BackButton';
import ProfileImage from '../images/profile-image.png';
import { getLeaderboard } from '../modules/gamePlay/actions';
import { ReactComponent as Rank1 } from '../images/rank-1.svg';
import numberSuffixer from '../util/numberSuffixer';

export default function LeaderBoard({ history }) {
  const dispatch = useDispatch();
  const { username, current_points, current_rank } = useSelector(
    state => state.player.info
  );
  const { leaderboard } = useSelector(state => state.gamePlay);
  const connection = useSelector(state => state.app.ws.connection);
  const handleButtonOnClick = () => history.push('/');

  useEffect(() => {
    if (connection === 'connected') {
      dispatch(getLeaderboard());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-auto">
          <BackButton
            pageName="Leaderboard"
            onClick={() => handleButtonOnClick()}
          />
        </div>
      </div>

      <div className="mt-4 pt-1">
        <Profile>
          <Box>
            <div className="row justify-content-center">
              <div className="col-auto">
                <ImageObject>
                  <Image circular fluid src={ProfileImage} />
                </ImageObject>
                <Username>{username}</Username>
              </div>
            </div>

            <div className="row justify-content-around position-relative">
              <Topper />
              <div className="col-6">
                <Matrix>
                  <NumberFormat
                    displayType="text"
                    value={current_points ? current_points : '0.00'}
                    thousandSeparator=","
                  />
                </Matrix>
              </div>
              <Divider></Divider>
              <div className="col-6">
                <Matrix>
                  {current_rank ? numberSuffixer(current_rank) : 'Not Ranked'}
                </Matrix>
              </div>
            </div>
          </Box>
        </Profile>
      </div>

      <div className="mt-4 pt-1 position-relative">
        {Object.entries(leaderboard).length <= 0 ? (
          <Loader active />
        ) : (
          <LeaderBoardBox>
            {leaderboard.map((item, i) => (
              <Player key={i} className="d-flex align-items-center">
                <Rank>{YourRank(item.rank)}</Rank>
                <Avatar>
                  {item.image_path && (
                    <Image fluid circular src={item.image_path} />
                  )}
                </Avatar>
                <GameMeta>
                  <UserName>{item.username}</UserName>
                  <Points>
                    <NumberFormat
                      displayType="text"
                      value={item.points ? item.points : '0.00'}
                      thousandSeparator=","
                      suffix=" pts"
                    />
                  </Points>
                </GameMeta>
              </Player>
            ))}
          </LeaderBoardBox>
        )}
      </div>
    </div>
  );
}
const YourRank = rank => {
  switch (rank) {
    case 1:
      return <Rank1 />;

    default:
      return rank;
  }
};
const Profile = styled.div`
  padding-top: 30px;
`;
const Box = styled.div`
  border-radius: 4px;
  background-color: rgba(5, 22, 87, 0.8);
`;
const ImageObject = styled.div`
  width: 56px;
  height: 56px;
  position: relative;
  margin: -45% auto 0;
`;
const Username = styled.div`
  color: #00a0f5;
  font-size: 1.071rem;
  font-weight: bold;
  margin-top: 6px;
  margin-bottom: 8px;
`;
const Divider = styled.div`
  background-color: rgba(0, 160, 245, 0.3);
  width: 1px;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0, -50%);
  height: 70%;
`;
const Topper = styled.span`
  position: absolute;
  display: block;
  width: 90%;
  height: 1px;
  left: 50%;
  top: 0;
  transform: translate(-50%);
  background-color: rgba(0, 160, 245, 0.3);
`;
const Matrix = styled.p`
  color: #ffc800;
  font-size: 1.286rem;
  padding: 8px 0;
  margin-bottom: 0;
  text-align: center;
`;
const Player = styled.div`
  background-color: rgba(5, 22, 87, 0.5);
  min-height: 30px;
  padding: 13px 15px 8px;
`;
const LeaderBoardBox = styled.div`
  position: relative;
  min-height: 56px;

  > ${Player}:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  > ${Player}:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  ${Player}:nth-child(even) {
    background-color: rgba(5, 22, 87, 0.8);
  }
`;
const Avatar = styled.div`
  width: 37.17px;
  height: 37.17px;
  border-radius: 50%;
  background-color: #eb00ff;
  margin-left: 20px;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;
const UserName = styled.span``;
const Points = styled.span``;
const Rank = styled.span`
  color: #ffc800;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  width: 26px;

  img,
  svg {
    width: 26px;
    height: 35px;
  }
`;
const GameMeta = styled.div`
  flex: 1;
  margin-left: 17px;

  ${UserName} {
    font-size: 16px;
    color: #fff;
    display: block;
  }

  ${Points} {
    font-size: 14px;
    color: #00a0f5;
  }
`;
