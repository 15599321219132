import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

function ChancesCount() {
  const chances = useSelector(state => state.player.info.chances);
  const [chancesState, setChancesState] = useState(chances);
  const maxChances = 3;

  useEffect(() => {
    setChancesState(chances);
  }, [chances]);

  return (
    <Chance>
      <div className="progress">
        {[...new Array(maxChances)].map((num, i) => (
          <span
            key={i}
            className={maxChances - i <= chancesState ? 'lit' : ''}
          ></span>
        ))}
      </div>
      <div className="count">
        <span>{chancesState}x</span>
      </div>
    </Chance>
  );
}

const Chance = styled.div`
  display: flex;
  align-items: center;

  .count {
    background-color: #004fd4;
    color: #ffc800;
    font-family: TitanOne, 'Gill Sans MT', Calibri, 'Trebuchet MS';
    padding: 4px 3px;

    span {
      background-color: #fff;
      padding: 0 3px;
      display: flex;
      width: 100%;
      height: 100%;
      font-size: 10px;
    }

    &,
    span {
      border-radius: 8px 4px 4px 9px;
    }
  }

  .progress {
    height: 15px;
    background-color: #004fd4;
    border-radius: 3px 0 0 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transform: translateX(2px);
    padding: 0 5px;

    span {
      width: 12px;
      height: 5px;
      border: 1px solid #fff;
      border-radius: 5px;

      &:not(:last-child) {
        margin-right: 2px;
      }

      &.lit {
        background-color: #fbb200;
      }
    }
  }
`;

export default ChancesCount;
