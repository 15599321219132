import React from 'react';
import styled from 'styled-components';

import Points from '../Points';
import { Timer } from '../Timer';
import { ReactComponent as Close } from '../../images/close.svg';
import ChancesCount from '../ChancesCount';

function QuestionsControl({
  points,
  close,
  onClose,
  timer,
  timerState,
  count,
  onTimerComplete,
  chances
}) {
  return (
    <div className="row page-control">
      <div className="col-auto mr-auto">
        {close && (
          <div
            className="closeButton"
            style={{ marginTop: '3px' }}
            onClick={onClose}
          >
            <Close />
          </div>
        )}
      </div>
      <StylePoints className="col-auto mx-auto">
        {typeof points !== undefined && <Points points={points} />}
      </StylePoints>
      <div className="col-auto ml-auto">
        {timer && (
          <Timer
            key={count || 0}
            state={timerState}
            duration={timer}
            onCompleted={() => onTimerComplete()}
          />
        )}

        {chances && <ChancesCount />}
      </div>
    </div>
  );
}

const StylePoints = styled.div`
  .points-wrapper .inner {
    min-width: 125px;

    .points {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

QuestionsControl.defaultProps = {
  timerState: 'play'
};

export default QuestionsControl;
