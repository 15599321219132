import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';

import BackButton from '../components/BackButton';
import { ReactComponent as AvatarImage } from '../images/avatar.svg';
import { ReactComponent as Edit } from '../images/edit.svg';
import { ReactComponent as Award } from '../images/award.svg';
import { sexify } from '../util/formatPhoneNumber';
import { uploadProfileImage } from '../modules/player/actions';
import AchievementItem from './Profile/AchievementItem';
import PlayerDetailsItem from './Profile/PlayerDetailsItem';

function Profile({ history }) {
  const dispatch = useDispatch();
  const {
    username,
    msisdn,
    overall_achievements,
    image_path,
    player_info
  } = useSelector(state => state.player.info);

  const handleButtonOnClick = () => history.push('/');

  const ImageRef = useRef();
  const IPH = useRef();
  const handleImageInputOnChange = event => {
    let errs = [];
    const types = ['image/png', 'image/jpeg', 'image/gif'];
    const file = event.target.files[0];

    if (file.size > 150000) {
      // errs.push('Image is too big');
    }

    if (types.every(type => file.type !== type)) {
      console.log(`%c'${file.type}' is not a supported format`, 'color:yellow');
      errs.push(`%c'${file.type}' is not a supported format`);
    }

    if (errs.length <= 0) {
      const reader = new FileReader();

      reader.onloadend = function() {
        processFile(reader.result, file.type);
      };

      reader.onerror = function() {
        alert('There was an error reading the file!');
      };

      reader.readAsDataURL(file);
    } else {
      alert(errs);
    }
  };

  const processFile = (dataURL, fileType) => {
    const maxWidth = 320;
    const maxHeight = 320;

    const image = new Image();
    image.src = dataURL;
    image.onload = function() {
      const width = image.width;
      const height = image.height;
      const shouldResize = width > maxWidth || height > maxHeight;

      if (!shouldResize) {
        sendImage(dataURL);
        return;
      }
      let newWidth;
      let newHeight;

      if (width > height) {
        newHeight = maxHeight;
        newWidth = (maxHeight / height) * width;
      } else {
        newWidth = maxWidth;
        newHeight = (maxWidth / width) * height;
      }

      const canvas = document.createElement('canvas');

      canvas.width = newWidth;
      canvas.height = newHeight;

      var context = canvas.getContext('2d');

      context.drawImage(image, 0, 0, newWidth, newHeight);

      dataURL = canvas.toDataURL(fileType);

      sendImage(dataURL);
    };
  };

  const sendImage = imageData => {
    IPH.current.style.backgroundImage = `url(${imageData})`;
    dispatch(uploadProfileImage(imageData));
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-auto">
          <BackButton
            pageName="Profile"
            onClick={() => handleButtonOnClick()}
          />
        </div>
      </div>

      <div className="mt-4 pt-1">
        <div className="row flex-column">
          <div className="d-flex justify-content-center">
            <Avatar>
              <StyleEdit onClick={() => ImageRef.current.click()}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageInputOnChange}
                  ref={ImageRef}
                />
                <Edit />
              </StyleEdit>

              <ImagePlaceholder
                style={image_path && { backgroundImage: `url(${image_path})` }}
                id="ProfilePageAvatar"
                ref={IPH}
              />
            </Avatar>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <ProfileName>
              {username}
              <NumberFormat
                displayType="text"
                value={sexify(msisdn)}
                format="### ### ####"
              />
            </ProfileName>
          </div>
        </div>
      </div>

      <div className="mt-3 pt-1">
        <Box>
          <div className="inner">
            <Header>
              <AvatarImage />
              <div className="align-self-center">
                <h4>Personal Info</h4>
              </div>
            </Header>

            <hr />
            {player_info.length > 0 &&
              player_info.map((item, i) => {
                return <PlayerDetailsItem key={i} data={item} />;
              })}
          </div>
        </Box>
      </div>

      <div className="mt-3 pt-1">
        <Box className="awards">
          <div className="inner">
            <Header>
              <Award />
              <div className="align-self-center">
                <h4>Awards</h4>
              </div>
            </Header>

            <hr />
            {overall_achievements &&
            Object.entries(overall_achievements).length > 0 ? (
              Object.keys(overall_achievements).map((code, i) => (
                <AchievementItem
                  key={i}
                  code={code}
                  count={overall_achievements[code]}
                />
              ))
            ) : (
              <AchievementItem />
            )}
          </div>
        </Box>
      </div>
    </div>
  );
}

const Header = styled.div`
  display: flex;

  h4 {
    font-size: 0.875rem;
    font-weight: bold;
    margin-bottom: 0;
  }

  svg {
    width: 21px;
    height: 21px;
    margin-right: 12px;

    circle {
      fill: #ff2700;
    }
  }
`;
const Box = styled.div`
  color: #fff;
  border-radius: 4px;
  background-color: rgba(5, 22, 87, 0.8);

  .inner {
    padding: 15px;
  }

  hr {
    opacity: 0.2;
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
`;
const StyleEdit = styled.button`
  background-color: transparent;
  border: 0;
  z-index: 1;

  input[type='file'] {
    display: none;
  }
`;
const ImagePlaceholder = styled.div`
  border-radius: 50%;
  overflow: hidden;
  background-color: #004fd4;
`;
const Avatar = styled.div`
  position: relative;

  &,
  ${ImagePlaceholder} {
    width: 100.41px;
    height: 100.41px;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  ${StyleEdit} {
    width: 32px;
    height: 32px;
    position: absolute;
    right: -5px;
    padding: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

const ProfileName = styled.h1`
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;

  span {
    margin-top: 5px;
    display: block;
    font-size: 0.875rem;
    font-weight: normal;
  }
`;

export default Profile;
