import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Popeye from '../../../components/Popeye';
import GameCompleted from '../../../components/GameCompleted';
import { reset } from '../../../modules/qod/actions';
import { updateQODStatus } from '../../../modules/player/actions';

function Completed({ points, bonus, username, handleOnConfirm }) {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(reset());
      dispatch(updateQODStatus(true));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Popeye show={true} key={1}>
      <GameCompleted
        points={points}
        bonus={bonus}
        content={
          <p>
            <strong>Awesome {username}!</strong> <br />
            You answered correctly. Come back tomorrow and have another go.
          </p>
        }
        onConfirm={handleOnConfirm}
      />
    </Popeye>
  );
}

export default Completed;
