import * as onBoardSaga from './onboard/saga';

const sagas = {
  ...onBoardSaga
};

export function registerWithMiddleware(middleware: { run: Function }) {
  for (let name in sagas) {
    middleware.run(sagas[name]);
  }
}
