import React, { useEffect } from 'react';
import useQuery from '../../util/use-query';

const Subscriptions = ({ history }) => {
  let query = useQuery();

  useEffect(() => {
    for (const [key, value] of query) {
      console.log({ [key]: value });
    }
    if (query.get('result') === 'false') {
      history.push('/on-board?results=subscription_failed');
    }
    if (query.get('result') === 'true') {
      const number = query.get('msisdn');
      history.push('/on-board?number=' + number);
    }
    // eslint-disable-next-line
  }, [query]);
  return <>Hey there subscriber</>;
};

export default Subscriptions;
