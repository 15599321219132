import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

function NoMoreQuestions({ open, onClose }) {
  return (
    <Modal open={open} className={`notification`}>
      <Modal.Header>
        <h1>&nbsp;</h1>
      </Modal.Header>

      <Modal.Content>
        <Modal.Description className={`transition`}>
          <p>
            Time flies when you're having fun. Come back later for fresh
            questions
          </p>
        </Modal.Description>
      </Modal.Content>

      <Modal.Actions>
        <Button primary={true} size="huge" onClick={onClose}>
          Okay
          <span className="fill"></span>
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default NoMoreQuestions;
