import React, { useRef } from 'react';
import { Image, Loader } from 'semantic-ui-react';
import styled from 'styled-components';

import { ReactComponent as Arrow } from '../../../images/back-button.svg';

function GameItem({ item, loadContent, onClick }) {
  const target = useRef();

  const handleLoadContent = (name, event) => {
    target.current = event.target;
    if (!target.current.classList.contains('disabled')) {
      target.current.classList.add('loading');
      target.current.querySelector('.ui.loader').classList.remove('disabled');
      target.current.querySelector('.ui.loader').classList.add('active');

      const items = document.querySelectorAll(
        `.${Game.componentStyle.componentId}`
      );
      for (let i = 0; i < items.length; i++) {
        if (target.current.innerHTML !== items[i].innerHTML) {
          items[i].classList.add('disabled');
        }
      }

      loadContent(name.replace(/\s+/g, '-').toLowerCase());
    }
  };

  return (
    <Game
      key={item.name}
      onClick={onClick ? onClick : event => handleLoadContent(item.name, event)}
    >
      <GameIcon>
        <Image fluid src={item.icon} />
      </GameIcon>

      <Body>
        <Title>{item.name}</Title>
        <Desc>{item.desc}</Desc>
      </Body>

      <div className="align-self-center">
        <Loader size="mini" inline disabled />
        <Arrow />
      </div>
    </Game>
  );
}

const Game = styled.div`
  display: flex;
  width: 100%;
  border-radius: 10px;
  padding: 19px 20px;
  margin-bottom: 10px;
  background-color: rgba(0, 160, 245, 0.3);
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  svg {
    transform: rotate(180deg);
    width: 5.8px;
    height: 9.42px;
  }

  &.loading {
    svg {
      display: none;
    }
    .ui.loading {
    }
  }

  &.disabled {
    opacity: 0.3;
  }
`;
const GameIcon = styled.div`
  width: 61px;
  height: 61px;
  margin-right: 14px;
`;
const Body = styled.div`
  flex: 1;
`;
const Title = styled.h2`
  color: #fff;
  margin-bottom: 0;
  font-size: 1.125rem;
  font-weight: bold;
  margin-top: 2px;
`;
const Desc = styled.div`
  p {
    font-size: 0.75rem;
    margin-bottom: 0;
  }
`;

export default GameItem;
