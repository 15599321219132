import {
  SHOW_SPINNER,
  HIDE_SPINNER,
  SHOW_COVER,
  HIDE_COVER,
  SHOW_BILLING,
  HIDE_BILLING,
  NOTIFY,
  HIDE_NOTIFY
} from './actions';

const initialState = {
  spinner: false,
  cover: true,
  modal: false,
  meta: {
    name: ''
  },
  notify: {
    status: false,
    alert: 'info',
    message: ''
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_SPINNER:
      return { ...state, spinner: true };

    case HIDE_SPINNER:
      return { ...state, spinner: false };

    case SHOW_COVER:
      return { ...state, cover: true };

    case HIDE_COVER:
      return { ...state, cover: false };

    case SHOW_BILLING:
      return { ...state, billing: true };

    case HIDE_BILLING:
      return { ...state, billing: false };

    case NOTIFY:
      return {
        ...state,
        notify: {
          status: true,
          alert: action.meta.alert || 'info',
          message: action.message
        }
      };

    case HIDE_NOTIFY:
      return {
        ...state,
        notify: {
          status: false,
          alert: 'info',
          message: ''
        }
      };

    default:
      return state;
  }
}
