import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Image } from 'semantic-ui-react';

import GetReady from '../../../components/GetReady';
import Points from '../../../components/Points';
import QODIcon from '../../../images/game-icons/qod.png';
import BackButton from '../../../components/BackButton';
import { getQOD } from '../../../modules/qod/actions';
import { billPlayer } from '../../../modules/player/actions';
import BillPlayerModal from '../../../components/BillPlayerModal';

const Ready = ({ onBack, okAction, points }) => {
  const dispatch = useDispatch();
  const letsGo = useRef();

  const handleOnClick = event => {
    const letsGoButton = event.currentTarget;
    letsGoButton.classList.add('loading');
    letsGo.current = letsGoButton;
    dispatch(getQOD());
  };

  const handleBilling = () => {
    dispatch(billPlayer());
  };

  const handleBilled = () => {
    letsGo.current.classList.add('loading');
    dispatch(getQOD());
  };

  return (
    <React.Fragment>
      <GetReady
        show={true}
        pageControl={<Controls goBack={onBack} points={points} />}
        icon={<Icon />}
        content={<Description />}
        okAction={{
          text: "Let's Go",
          onClick: event => handleOnClick(event)
        }}
        noAction={{
          text: 'Chicken Out',
          onClick: onBack
        }}
      />
      <BillPlayerModal
        trigger={letsGo}
        onBill={handleBilling}
        onBillSuccess={handleBilled}
      />
    </React.Fragment>
  );
};

const Controls = ({ goBack, points }) => {
  return (
    <React.Fragment>
      <div className="col-auto mr-auto">
        <BackButton close={true} onClick={() => goBack()} />
      </div>
      <div className="col ml-auto">
        <Points position="right" points={points} />
      </div>
    </React.Fragment>
  );
};

const Icon = () => (
  <div
    className=""
    style={{ width: '130px', marginTop: '75px', marginBottom: '25px' }}
  >
    <Image fluid src={QODIcon} />
  </div>
);

const Description = () => (
  <p style={{ maxWidth: '280px', textAlign: 'center' }}>
    One question, one correct answer <br />
    and more points than you can think <br />
    of, whenever you are ready.
  </p>
);

export default Ready;
