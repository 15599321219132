import {
  GET_QOD,
  GET_QOD_SUCCESS,
  GET_QOD_ERROR,
  PROVE_ANSWER,
  PROVE_ANSWER_RECEIVED,
  PROVE_ANSWER_FAILED,
  CORRECT_ANSWER,
  WRONG_ANSWER,
  NEXT_ATTEMPT,
  storeQuestion
} from './actions';
import { apiRequest } from '../app/actions';
import { notify } from '../ui/actions';
import { updatePlayerPoints, UPDATE_QOD_CHANCES } from '../player/actions';
import { sexifyIntl } from '../../util/formatPhoneNumber';

export const getQODFlow = ({ dispatch, getState }) => next => action => {
  next(action);
  if (GET_QOD === action.type) {
    const {
      player: {
        token,
        info: { msisdn }
      }
    } = getState();

    dispatch(
      apiRequest(
        'get',
        `getQOD/${sexifyIntl(msisdn)}/${token}`,
        null,
        { type: GET_QOD_SUCCESS },
        { type: GET_QOD_ERROR }
      )
    );
  }

  if (GET_QOD_SUCCESS === action.type) {
    const {
      app: {
        api: {
          data: { question }
        }
      }
    } = getState();

    dispatch(storeQuestion(question[0]));
  }

  if (GET_QOD_ERROR === action.type) {
    // dispatch({ type: SHOULD_BILL });
    dispatch(notify('', 'info'));
  }
};

export const answerQuestionFlow = ({
  dispatch,
  getState
}) => next => action => {
  next(action);

  if (PROVE_ANSWER === action.type) {
    const {
      player: {
        token,
        info: { msisdn }
      },
      qod: {
        question: { question_id }
      }
    } = getState();

    dispatch(
      apiRequest(
        'post',
        `validateQODAnswer/${sexifyIntl(msisdn)}/${token}`,
        JSON.stringify({ [question_id]: action.answer }),
        { type: PROVE_ANSWER_RECEIVED },
        { type: PROVE_ANSWER_FAILED }
      )
    );
  }

  if (PROVE_ANSWER_RECEIVED === action.type) {
    const {
      app: {
        api: {
          data: { correct_answers, points, bonus, total_points }
        }
      }
    } = getState();

    if (correct_answers > 0) {
      dispatch({ type: CORRECT_ANSWER, score: points, bonus });
      dispatch(updatePlayerPoints(total_points));
    } else {
      dispatch({ type: WRONG_ANSWER });
    }
  }

  if (NEXT_ATTEMPT === action.type) {
    dispatch({ type: UPDATE_QOD_CHANCES });
    dispatch({ type: GET_QOD });
  }
};

const qodMdl = [getQODFlow, answerQuestionFlow];
export default qodMdl;
