import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import {
  STORE_LEADERBOARD,
  UPDATE_USERNAME_MODAL,
  STORE_ACHIEVEMENTS,
  ACHIEVEMENT_UNLOCKED,
  SET_NOTIFICATION,
  STORE_CAMPAIGN,
  UPDATE_CAMPAIGN_IMAGE,
  UPDATE_CAMPAIGN_COUNTDOWN,
  CAMPAIGN_STATUS,
  NEW_CAMPAIGN_OPT,
  OPT_IN,
  SET_TOAST,
  SHOW_TOASTS,
  IN_GAME_STATUS,
  QUESTION_FORMAT
} from './actions';

const initialState = {
  leaderboard: {},
  settings: {
    showUsernameModal: true,
    riskItBaskets: 3,
    app: {
      notifications: true,
      inGameMusic: false,
      soundEffects: false,
      qod: false
    }
  },
  campaign: {
    running: false,
    opt_in: 0,
    history: {},
    meta: {}
  },
  achievements: [],
  unlocked: [],
  toasts: {},
  inGame: false,
  questionFormat: 'standard'
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case STORE_LEADERBOARD:
      return { ...state, leaderboard: action.leaderboard };

    case UPDATE_USERNAME_MODAL:
      return {
        ...state,
        settings: { ...state.settings, showUsernameModal: action.state }
      };

    case STORE_ACHIEVEMENTS:
      return { ...state, achievements: action.payload };

    case ACHIEVEMENT_UNLOCKED:
      return { ...state, unlocked: [...state.unlocked, action.achievement] };

    case SET_NOTIFICATION:
      return {
        ...state,
        settings: {
          ...state.settings,
          app: {
            ...state.settings.app,
            [action.meta.name]: action.toggle
          }
        }
      };

    case STORE_CAMPAIGN:
      return {
        ...state,
        campaign: { ...state.campaign, running: true, meta: action.payload }
      };

    case CAMPAIGN_STATUS:
      return {
        ...state,
        campaign: { ...state.campaign, running: action.status }
      };

    case UPDATE_CAMPAIGN_IMAGE:
      return {
        ...state,
        campaign: {
          running: false,
          meta: {
            ...state.campaign.meta,
            image_path: action.path,
            is_qod_active: false
          }
        }
      };

    case UPDATE_CAMPAIGN_COUNTDOWN:
      return {
        ...state,
        campaign: {
          meta: {
            ...state.campaign.meta,
            next_campaign_start: action.time
          }
        }
      };

    case NEW_CAMPAIGN_OPT:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          opt_in: 0,
          history: action.payload
        }
      };

    case OPT_IN:
      return {
        ...state,
        campaign: { ...state.campaign, opt_in: action.status }
      };

    case SET_TOAST:
      return {
        ...state,
        toasts: {
          ...state.toasts,
          [action.meta.key]: action.message
        }
      };

    case SHOW_TOASTS:
      return {
        ...state,
        toasts: {}
      };

    case IN_GAME_STATUS:
      return { ...state, inGame: action.status };

    case QUESTION_FORMAT:
      return { ...state, questionFormat: action.format };

    default:
      return state;
  }
}

const persistAchievements = {
  key: 'gamePlay',
  whitelist: ['achievements', 'settings', 'campaign'],
  storage,
  stateReconciler: autoMergeLevel2
};

export default persistReducer(persistAchievements, reducer);
