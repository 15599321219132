import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Detail, Meta, Status } from './DetailComponent';
import { ReactComponent as Arrow } from '../../images/back-button.svg';
import { updatePlayerInfo, logUpdateField } from '../../modules/player/actions';
import Input from './FormControls/Input';
import Select from './FormControls/Select';

function PlayerDetailsItem({ data }) {
  const dispatch = useDispatch();
  const { updatedField } = useSelector(state => state.player);
  const { title, value, key, scheme } = data;
  const [saveChanges, setSaveChanges] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef();
  const buttonRef = useRef();

  const handleOnChange = event => {
    const input = event.target;
    setInputValue(input.value);

    if (input.value.length > 0) {
      input.parentElement.parentElement.parentElement.classList.add('dirty');
      setSaveChanges(true);
    } else {
      input.parentElement.parentElement.parentElement.classList.remove('dirty');
      setSaveChanges(false);
    }
  };

  const onFocus = () => {
    inputRef.current.parentElement.parentElement.parentElement.classList.add(
      'dirty'
    );
  };
  const onBlur = () => {
    if (!(inputRef.current.value.length > 0)) {
      inputRef.current.parentElement.parentElement.parentElement.classList.remove(
        'dirty'
      );
    }
  };

  const handleEditMode = () => {
    if (scheme.type !== 'list') {
      inputRef.current.focus();
    }
    setSaveChanges(true);
    setEditMode(true);
  };

  const handleSave = event => {
    event.target.classList.add('loading');
    buttonRef.current = event.target;
    // console.log(inputRef.current.value);
    dispatch(updatePlayerInfo(key, inputRef.current.value));
  };

  useEffect(() => {
    if (value && value.length > 0) {
      setInputValue(value);
      setEditMode(false);
    } else {
      setEditMode(true);
    }
  }, [value]);

  useEffect(() => {
    if (updatedField !== undefined && updatedField[key] === 'done') {
      buttonRef.current.classList.remove('loading');
      setEditMode(false);
      dispatch(logUpdateField(key, ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedField]);

  return (
    <Detail
      className={`${value && value.length > 0 ? 'dirty' : ''} ${
        editMode ? 'editing' : ''
      }`}
    >
      <span className="blinking-cursor"></span>
      <Meta>
        {(() => {
          switch (scheme.type) {
            case 'list':
              const options = scheme.data.map((option, key) => ({
                key,
                text: option,
                value: option
              }));
              return (
                <Select
                  {...{
                    options,
                    title,
                    handleEditMode,
                    inputRef,
                    value,
                    setInputValue
                  }}
                />
              );

            default:
              return (
                <Input
                  {...{
                    title,
                    handleOnChange,
                    onFocus,
                    onBlur,
                    inputValue,
                    inputRef
                  }}
                />
              );
          }
        })()}

        <div className="readonly">{inputValue}</div>
      </Meta>
      <Status className="d-flex">
        {editMode ? (
          saveChanges ? (
            <button className="ui button" onClick={handleSave}>
              Save
            </button>
          ) : (
            'Not Set'
          )
        ) : (
          <button className="ui button" onClick={handleEditMode}>
            Edit
          </button>
        )}
        <Arrow className="align-self-center" />
      </Status>
    </Detail>
  );
}

export default PlayerDetailsItem;
