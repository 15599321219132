import React from 'react';
import { Button, Image } from 'semantic-ui-react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';

import CongratsImage from '../images/congrats.png';

const GameCompleted = ({
  content,
  points,
  bonus,
  onConfirm,
  enablePlayAgain,
  onPlayAgain
}) => {
  return (
    <div className="container GameSessionComplete">
      <div className="row justify-content-center">
        <MediaObject>
          <Image fluid src={CongratsImage} />
        </MediaObject>
      </div>

      <div className="row justify-content-center">
        <StyledPoints>
          <NumberFormat
            displayType="text"
            value={points ? points : '0.00'}
            thousandSeparator=","
            suffix=" pts"
          />
          {bonus > 0 && (
            <Bonus>
              {' '}
              Bonus:
              <NumberFormat
                displayType="text"
                value={bonus}
                thousandSeparator=","
                suffix=" pts"
              />
            </Bonus>
          )}
        </StyledPoints>
      </div>

      <Content className="row justify-content-center">{content}</Content>

      <div className="row justify-content-center">
        <div className="px-4 pt-3 mt-4 w-100">
          {enablePlayAgain ? (
            <>
              <Button primary fluid size="huge" onClick={onPlayAgain}>
                Play Again
                <span className="fill"></span>
              </Button>
              <div className="row justify-content-center mt-4">
                <Button className="noButton" id="noButton" onClick={onConfirm}>
                  Done
                </Button>
              </div>
            </>
          ) : (
            <Button primary fluid size="huge" onClick={onConfirm}>
              Okay
              <span className="fill"></span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const MediaObject = styled.div`
  margin-bottom: 8px;
  width: 202px;
`;

const StyledPoints = styled.div`
  background-color: #fff;
  border: 1px solid #00a0f5;
  border-radius: 4px;
  *height: 40px;
  padding: 0.571429em 0.857em 0.429em;
  text-align: center;
  line-height: 0.42857;
  font-size: 1.786rem;
  font-weight: bold;
  margin-bottom: 35px;
`;

const Content = styled.div`
  font-color: #fff;
  font-size: 1.286rem;

  p {
    text-alight: center;
    line-height: 24px;
  }
`;

const Bonus = styled.div`
  display: block;
  margin-top: 20px;
  color: #ffc800;
  font-size: 16px;

  span {
    margin-left: 10px;
  }
`;

GameCompleted.defaultProps = {
  content: (
    <p>
      Well Played Adwoa! <br />
      You got <strong>10/10</strong> correct.
    </p>
  ),
  points: '500 pts'
};

export default GameCompleted;
