import {
  QUESTION_FAILED,
  STORE_QUESTION,
  ANSWER,
  CORRECT_ANSWER,
  WRONG_ANSWER,
  UPDATE_POINTS,
  REDUCE_SPRINT_COUNT,
  EXHAUSTED,
  END_SPRINT,
  RESET,
  SET_QUESTION_STATUS
} from './actions';

const initialState = {
  question: {},
  count: 0,
  attempt_limit: parseInt(process.env.REACT_APP_SPRINT_QUESTION_LIMIT),
  completed: false,
  answer: '',
  sprint_points: 0,
  correct_count: 0,
  noMoreQuestions: false,
  affects_trigger_available: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ANSWER:
      return { ...state, answer: '' };

    case CORRECT_ANSWER:
      return {
        ...state,
        answer: 'correct',
        correct_count: state.correct_count + 1
      };

    case WRONG_ANSWER:
      return { ...state, answer: 'wrong' };

    case UPDATE_POINTS:
      return { ...state, sprint_points: state.sprint_points + action.points };

    case REDUCE_SPRINT_COUNT:
      return {
        ...state,
        player: {
          ...state.player,
          info: {
            ...state.player.info,
            unanswered_trigger_count:
              state.player.info.unanswered_trigger_count - 1
          }
        }
      };

    case QUESTION_FAILED:
      return { ...state, error: true };

    case STORE_QUESTION:
      return {
        ...state,
        question: action.payload,
        count: state.count + 1,
        affects_trigger_available: null
      };

    case END_SPRINT:
      return { ...state, completed: true };

    case EXHAUSTED:
      return { ...state, noMoreQuestions: true };

    case RESET:
      return {
        ...state,
        question: {},
        completed: false,
        count: 0,
        answer: '',
        sprint_points: 0,
        correct_count: 0,
        noMoreQuestions: false
      };

    case SET_QUESTION_STATUS:
      return {
        ...state,
        affects_trigger_available: action.status
      };

    default:
      return state;
  }
}
