import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { IMAGE_URL_REGEX } from '../../../modules/gamePlay/actions';

function Image({ content, setTimer }) {
  const [questionImage, setQuestionImage] = useState('');
  const [question, setQuestion] = useState('');

  const handleOnLoad = () => {
    // setTimer('resume');
  };

  useEffect(() => {
    // setTimer('pause');
    if (content !== null) {
      const url = content.match(IMAGE_URL_REGEX);
      const text = content.replace(IMAGE_URL_REGEX, '');
      setQuestion(text);
      setQuestionImage(url[0]);
    }

    // eslint-disable-next-line
  }, [content]);

  return (
    <Wrapper>
      <p>{question}</p>
      <ImageWrapper>
        <img src={questionImage} onLoad={handleOnLoad} alt="" />
      </ImageWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 190px;

  img {
    width: auto;
    height: 100%;
  }
`;
const ImageWrapper = styled.div`
  height: 110px;
  display: flex;
  justify-content: center;

  img {
    height: 100% !important;
  }
`;

export default Image;
