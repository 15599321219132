import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Popeye from '../../../components/Popeye';
import GameCompleted from '../../../components/GameCompleted';
import { reset } from '../../../modules/RiskIt/actions';

const Completed = ({ history }) => {
  const dispatch = useDispatch();
  const { proven, attempts } = useSelector(state => state.riskit);
  const { current_points, username } = useSelector(state => state.player.info);

  const handleOnConfirm = () => {
    history.push('/arcade');
  };

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Popeye show={true}>
      <GameCompleted
        points={current_points}
        content={<Content {...{ proven, attempts, username }} />}
        onConfirm={handleOnConfirm}
      />
    </Popeye>
  );
};

const Content = ({ proven, attempts, username }) => {
  let salute = 'Hey',
    comment = '';

  if (attempts > 0) {
    if (proven) {
      salute = 'OMG';
      comment = 'You have doubled your points';
    } else {
      salute = 'Yikes';
      comment = 'Better luck next time.';
    }
  } else {
    salute = 'Aww Darn!';
    comment = 'Time run out on you huh.';
  }

  return (
    <p>
      <strong>
        {salute}, {username}
      </strong>{' '}
      <br />
      {comment}
    </p>
  );
};

export default Completed;
