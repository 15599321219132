// eslint-disable-next-line
import { persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import {
  WS_CONNECT,
  WS_CONNECTED,
  WS_DISCONNECTED,
  API_FEEDBACK,
  SYSTEM_ERROR,
  SUBSCRIBE_TOPIC,
  SW_INIT,
  SW_UPDATE,
  SW_UPDATING,
  SW_UPDATED
} from './actions';

const initialState = {
  sw: {
    init: false,
    update: false,
    updated: false,
    registration: null
  },
  ws: {
    socket: {},
    connection: 'offline',
    message: ''
  },
  internet: {
    online: true
  },
  api: {
    status: null,
    message: null
  },
  topics: {}
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case WS_CONNECT:
      return { ...state, ws: { ...state.ws, connection: 'connecting' } };

    case WS_CONNECTED:
      return {
        ...state,
        ws: { ...state.ws, connection: 'connected', socket: action.socket }
      };

    case WS_DISCONNECTED:
      return {
        ...state,
        ws: { ...state.ws, connection: 'disconnected', socket: {} }
      };

    case SW_INIT:
      return {
        ...state,
        sw: { ...state.sw, init: !state.sw.init }
      };

    case SW_UPDATE:
      return {
        ...state,
        sw: {
          ...state.sw,
          update: !state.sw.updated,
          updated: false,
          registration: action.payload
        }
      };

    case SW_UPDATING:
      return {
        ...state,
        sw: {
          ...state.sw,
          updated: true
        }
      };

    case SW_UPDATED:
      return {
        ...state,
        sw: {
          ...state.sw,
          init: false,
          update: false,
          updated: false,
          registration: null
        }
      };

    case SUBSCRIBE_TOPIC:
      return {
        ...state,
        topics: {
          ...state.topics,
          [action.topic]: {
            onReceive: action.meta.onReceive,
            onFailure: action.meta.onFailure
          }
        }
      };

    case API_FEEDBACK:
      return { ...state, api: { ...state.api, ...action.payload } };

    case SYSTEM_ERROR:
      return { ...state };

    default:
      return state;
  }
}

const migrations = {
  0: state => {
    return {
      ...state,
      sw: {
        init: false,
        update: false,
        updated: false,
        registration: null
      }
    };
  }
};
// eslint-disable-next-line
const persistServiceWorker = {
  key: 'SW',
  version: 0,
  whitelist: ['sw'],
  storage,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations, { debug: false })
};

export default reducer;
