import { apiRequest } from '../app/actions';
import { sexifyIntl } from '../../util/formatPhoneNumber';
import {
  GET_RISKIT,
  GET_RISKIT_SUCCESS,
  GET_RISKIT_ERROR,
  NO_QUESTION,
  PROVE_RISKIT,
  PROVE_RISKIT_SUCCESS,
  PROVE_RISKIT_ERROR,
  provedRiskit,
  notProveRiskit,
  storeQuestion,
  reset
} from './actions';
import { updatePlayerPoints, updateRiskitKeysCount } from '../player/actions';

export const getRiskItQuestionFlow = ({
  dispatch,
  getState
}) => next => action => {
  next(action);

  if (GET_RISKIT === action.type) {
    const {
      player: {
        token,
        info: { msisdn }
      }
    } = getState();

    dispatch(
      apiRequest(
        'get',
        `getRiskitQuestion/${sexifyIntl(msisdn)}/${token}`,
        null,
        { type: GET_RISKIT_SUCCESS },
        { type: GET_RISKIT_ERROR }
      )
    );
  }

  if (GET_RISKIT_SUCCESS === action.type) {
    const {
      app: {
        api: {
          data: { question }
        }
      }
    } = getState();

    dispatch(storeQuestion(question[0]));
  }

  if (GET_RISKIT_ERROR === action.type) {
    dispatch({ type: NO_QUESTION });
  }
};

export const validateRiskitAnswerFlow = ({
  dispatch,
  getState
}) => next => action => {
  next(action);

  if (PROVE_RISKIT === action.type) {
    const {
      player: {
        token,
        info: { msisdn }
      },
      riskit: {
        question: { question_id }
      }
    } = getState();

    dispatch(
      apiRequest(
        'post',
        `validateRiskitAnswer/${sexifyIntl(msisdn)}/${token}`,
        JSON.stringify({ [question_id]: action.answer }),
        { type: PROVE_RISKIT_SUCCESS },
        { type: PROVE_RISKIT_ERROR }
      )
    );
  }

  if (PROVE_RISKIT_SUCCESS === action.type) {
    const {
      app: {
        api: {
          data: { total_points, correct_answers }
        }
      }
    } = getState();

    if (correct_answers > 0) {
      dispatch(provedRiskit());
    } else {
      dispatch(notProveRiskit());
    }

    dispatch(updatePlayerPoints(total_points));
    dispatch(updateRiskitKeysCount());
  }

  if (PROVE_RISKIT_ERROR === action.type) {
    dispatch(reset());
  }
};

const riskItMdl = [getRiskItQuestionFlow, validateRiskitAnswerFlow];

export default riskItMdl;
