import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { getPostcode } from '../../../../modules/boosters/actions';

function PostCodeRegister({ history }) {
  let { postcode } = useParams();
  const {
    loading,
    info: { msisdn }
  } = useSelector(s => s.player);
  const { socket } = useSelector(s => s.app.ws);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.entries(socket).length > 0) {
      // check if user is logged in
      if (msisdn) {
        // if logged in, redirect to Boosters and select postcode
        history.replace('/arcade/boosters');
      } else {
        dispatch(getPostcode(postcode));
      }
    }
    // eslint-disable-next-line
  }, [loading, msisdn]);

  return <div>hey postcode register {postcode}</div>;
}

export default PostCodeRegister;
