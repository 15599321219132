import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Input, Button } from 'semantic-ui-react';

import { verifyPlayer } from '../../modules/onboard/actions';

function PhoneNumber({ defaultNumber }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [buttonReady, setButtonReady] = useState(false);
  const [animationClass, setAnimationClass] = useState('');

  const onChange = event => {
    const number = event.target.value;
    setPhoneNumber(number);

    if (number.length > 9) {
      setButtonReady(true);
    } else {
      setButtonReady(false);
    }

    if (number.length > 0) {
      setAnimationClass('entered');
    } else if (number.length === 0) {
      setAnimationClass('');
    }
  };

  const validateNumber = () => {
    if (phoneNumber.length > 9) {
      let number = '233' + phoneNumber.toString().slice(-9);
      setLoading(true);
      setButtonReady(false);
      dispatch(verifyPlayer(number));
    }
  };

  useEffect(() => {
    if (animationClass === 'entered') {
      window.scrollTo(0, document.body.scrollHeight);
    }

    if (defaultNumber !== '') {
      setPhoneNumber(defaultNumber);
      setAnimationClass('entered');
      setButtonReady(true);
    }
  }, [animationClass, defaultNumber]);

  return (
    <div id="join__element" className="col">
      <div className="inner">
        <Input
          fluid
          size="huge"
          placeholder="Enter your mobile number"
          type="tel"
          disabled={loading}
          value={phoneNumber}
          onChange={onChange}
        />
        <div id="joinButton" className={animationClass}>
          <Button
            size="huge"
            fluid
            loading={loading}
            disabled={!buttonReady}
            primary={buttonReady}
            onClick={validateNumber}
          >
            Let's Go
            <div className="fill"></div>
          </Button>

          <p style={{ textAlign: 'center', marginTop: '10px' }}>
            <a href="/" target="_blank" style={{ color: '#FFC800' }}>
              Terms &amp; conditions apply.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default PhoneNumber;
