import React from 'react';
import log from 'simple-node-logger';
import { Button } from 'semantic-ui-react';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  handleOnclick() {
    window.location.reload(true);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log('From witherror: ', error, errorInfo);
    log.createSimpleLogger('debug.log');
  }

  render() {
    if (this.state.hasError) {
      return (
        <div id="page">
          <div
            id="errorBoundary"
            className="container d-flex flex-column h-100"
          >
            <div className="row justify-content-center mt-auto">
              <div className="img"></div>
            </div>

            <div className="row justify-content-center">
              <div className="content">
                <h1>Something went wrong</h1>
                <p>
                  If this page appears a conservative time, kindly try one of
                  the following:
                </p>

                <ul>
                  <li>Check your internet connection</li>
                  <li>Clear your cache</li>
                </ul>
              </div>
            </div>

            <div className="row justify-content-center mt-auto mb-5">
              <div className="px-4 pt-2 mt-4 w-100">
                <Button primary fluid size="huge" onClick={this.handleOnclick}>
                  Reload
                  <span className="fill"></span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
