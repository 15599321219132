import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import GameItem from './GameItem';
import BillPlayerModal from '../../../components/BillPlayerModal';
import { notify } from '../../../modules/ui/actions';
import { billPlayer } from '../../../modules/player/actions';

function GameItemTriv5({ item }) {
  const triv5 = useRef();
  const dispatch = useDispatch();
  const { is_billed } = useSelector(s => s.player.info);

  const handleClick = event => {
    triv5.current = event.target;

    if (!is_billed) {
      // show bill popup
      dispatch(notify('', 'info'));
    } else {
      window.open(item.url, '_blank');
    }
  };

  const handleBilled = () => {
    dispatch(billPlayer());
  };

  const handleBillSuccess = () => {
    window.open(item.url, '_blank');
  };

  return [
    <GameItem key={0} item={item} onClick={handleClick} />,
    <BillPlayerModal
      key={1}
      trigger={triv5}
      onBill={handleBilled}
      onBillSuccess={handleBillSuccess}
    />
  ];
}

export default GameItemTriv5;
