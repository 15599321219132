import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  QuestionsControl,
  QuestionItem,
  QuestionOptions,
  setupOptions
} from '../../../components/Questions';
import {
  proveAnswer,
  nextAttempt,
  completedQOD
} from '../../../modules/qod/actions';

const Question = ({ current_points, question, route }) => {
  const selectedOption = useRef();
  const dispatch = useDispatch();
  const { proven, attempts } = useSelector(state => state.qod);

  const handleOptionClick = (answer, event) => {
    selectedOption.current = event.target;

    const optionItems = document.querySelectorAll('.ui.button.option');
    for (let i = 0; i < optionItems.length; i++) {
      if (selectedOption.current.innerHTML !== optionItems[i].innerHTML) {
        optionItems[i].classList.add('disabled');
        optionItems[i].disabled = true;
      }
    }

    selectedOption.current.classList.add('loading');
    dispatch(proveAnswer(answer));
  };

  const handleCancel = () => {
    route('');
  };

  useEffect(() => {
    if (proven !== null && attempts > 0) {
      selectedOption.current.classList.remove('loading');
      selectedOption.current.classList.add(
        proven === true ? 'correct' : 'wrong'
      );
      selectedOption.current
        .getElementsByClassName('animation-fill-other')[0]
        .addEventListener(
          'transitionend',
          function readyForNextQuestion() {
            if (!proven) {
              setTimeout(() => {
                dispatch(nextAttempt());
              }, 300);
            } else {
              dispatch(completedQOD());
            }

            selectedOption.current.removeEventListener(
              'transitionend',
              readyForNextQuestion,
              false
            );
          },
          false
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proven, attempts]);

  return (
    <div id="QODQuestion">
      <QuestionsControl
        close={true}
        onClose={handleCancel}
        onTimerComplete={() => {}}
        chances={true}
      />

      <QuestionItem
        content={<p>{question.question}</p>}
        raw={question.question}
        points={question.points}
      />

      <QuestionOptions
        key={question.chances}
        options={setupOptions(question)}
        onClick={(answer, event) => handleOptionClick(answer, event)}
      />
    </div>
  );
};

export default Question;
