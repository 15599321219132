import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import Popeye from './Popeye';

function GetReady({ pageControl, icon, content, okAction, noAction }) {
  const handleOnOkAction = event => {
    const noButton = document.getElementById('noButton');
    noButton.style.opacity = '0';
    okAction.onClick(event);
  };

  return (
    <Popeye show={true} align="top">
      <div className="container AreYouReady">
        <div className="row page-control">{pageControl}</div>

        <div className="row justify-content-center">
          <div className="object">{icon}</div>
        </div>

        <div className="row justify-content-center">{content}</div>

        <div className="row justify-content-center">
          <div className="px-4 pt-2 mt-4 w-100">
            <Button
              primary
              fluid
              size="huge"
              onClick={event => handleOnOkAction(event)}
            >
              {okAction.text}
              <span className="fill"></span>
            </Button>
          </div>
        </div>

        <div className="row justify-content-center mt-4">
          <Button className="noButton" id="noButton" onClick={noAction.onClick}>
            {noAction.text}
          </Button>
        </div>
      </div>
    </Popeye>
  );
}

GetReady.propTypes = {
  pageControl: PropTypes.any
};

export default GetReady;
