import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Image, Loader } from 'semantic-ui-react';

import BackButton from '../components/BackButton';
import { ReactComponent as TickImage } from '../images/tick-with-circle.svg';
import { getAchievements } from '../modules/gamePlay/actions';

const Achievements = ({ history }) => {
  const dispatch = useDispatch();
  const {
    info: { campaign_achievements }
  } = useSelector(state => state.player);
  const { achievements } = useSelector(state => state.gamePlay);
  const connection = useSelector(state => state.app.ws.connection);

  const handleButtonOnClick = () => history.push('/');

  const playerHas = achievement => {
    if (
      campaign_achievements !== null &&
      Object.entries(campaign_achievements).length > 0
    ) {
      // if (campaign_achievements.find(item => item.code === achievement))
      if (campaign_achievements[achievement] !== undefined) return true;
    }
  };

  useEffect(() => {
    if (
      !(Object.entries(achievements).length > 0) &&
      connection === 'connected'
    ) {
      dispatch(getAchievements());
    }
  }, [connection, achievements, dispatch]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-auto">
          <BackButton
            pageName="Achievement Wall"
            onClick={() => handleButtonOnClick()}
          />
        </div>
      </div>

      <div className="achievement-listings mt-4 pt-1 position-relative">
        {achievements.length > 0 ? (
          achievements.map((item, i) => (
            <Achievement key={i} className={playerHas(item.code) && 'achieved'}>
              <Achieved>
                <TickImage />
              </Achieved>
              <PostImage>
                <Image
                  fluid
                  src={item.image_path || process.env.REACT_APP_SM_IMAGE}
                />
              </PostImage>

              <Body className="align-self-center">
                <Title>{item.name}</Title>
                <p>{item.description}</p>
              </Body>
            </Achievement>
          ))
        ) : (
          <Loader active />
        )}
      </div>
    </div>
  );
};

const Achieved = styled.div``;
const Achievement = styled.div`
  display: flex;
  width: 100%;
  border-radius: 4px;
  padding: 20px 25px 20px 16px;
  margin-bottom: 10px;
  background-color: rgba(5, 22, 87, 0.5);
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
  }

  > ${Achieved} {
    display: none;
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    width: 33.42px;
    height: 33.42px;
  }
  &.achieved ${Achieved} {
    display: block;
  }
`;
const PostImage = styled.div`
  width: 48px;
  height: 48px;
  margin-right: 14px;
  border-radius: 8px;
  overflow: hidden;
`;
const Body = styled.div`
  flex: 1;
  color: #fff;

  p {
    font-size: 0.857rem;
    margin-bottom: 0;
  }
`;
const Title = styled.h2`
  color: #fff;
  margin-bottom: 2px;
  font-size: 1.143rem;
  font-weight: bold;
  margin-top: 0;
  position: relative;
`;

export default Achievements;
