import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import useQuery from '../util/use-query';

import Boards from './onBoard/Boards';
import PhoneNumber from './onBoard/PhoneNumber';
import OneTimePlay from './onBoard/OneTimePin';
import { hideCover } from '../modules/ui/actions';
// import onBoardBg from '../images/bg-on-boarding.png';

function OnBoard({ history, ...props }) {
  const query = useQuery();
  const dispatch = useDispatch();
  const isMounted = useRef(false);
  const [userNumber, setUserNumber] = useState('');
  const [activeComponent, setActiveComponent] = useState('on-board');
  const route = component => setActiveComponent(component);
  const progress = useSelector(state => state.onBoard.progress);
  const subscription_failed =
    query.get('results') && query.get('results') === 'subscription_failed';
  const {
    app: {
      ws: { connection }
    },
    player: {
      info: { msisdn }
    },
    onBoard: {
      player: { msisdn: number }
    }
  } = useSelector(state => state);

  useEffect(() => {
    if (connection === 'connected' && msisdn === '') {
      dispatch(hideCover());
    }

    if (!isMounted.current) {
      if (subscription_failed) {
        toast.error('Subscription failed. Kindly try again.');
      }

      if (query.get('number')) {
        setUserNumber(query.get('number').replace(/\D/g, ''));
      }

      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, [connection, msisdn]);

  useEffect(() => {
    if (progress === 'OTP-initiated') {
      route('otp');
    } else if (progress === 'completed') {
      // console.log('when completed: ',history)
      // history.location('/');
    } else if (progress === 'initiate_sub_widget') {
      fetch(
        'http://apps.mobivs.com/TELENITY/app.php?client_id=18e704437de770082101dfcd7123e988a5ad49ad&activity=token',
        {
          method: 'GET'
        }
      )
        .then(response => {
          if (response.ok) {
            return response.json();
          }

          throw response;
        })
        .then(data => {
          const { access_token } = data;
          const cb_base = 'http://www.brainwavegh.com/telenity/';
          const cb_query = encodeURIComponent(`number=${number}`);
          const callbackUrl =
            cb_base +
            'feedback.html?cb=' +
            window.location.origin +
            '/on-board?' +
            cb_query;
          console.log(callbackUrl);
          const query = encodeURIComponent(
            `token=${access_token}&action=signup&number=${number}&callbackUrl=${callbackUrl}`
          );
          const url = `http://www.brainwavegh.com/telenity/register?${query}`;
          // console.log(url);
          window.location = url;

          // 'register?' +
          // encodeURIComponent('token='+token_obj.access_token+'&callbackUrl='+token_obj.callback_url+'&action=' + action)
          // history.push('subscribe/'+number);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  switch (activeComponent) {
    case 'otp':
      return <OneTimePlay route={component => route(component)} />;

    default:
      return (
        <div className="container onboard__container d-flex flex-column h-100">
          <Boards />

          <div className="row justify-content-center mt-auto">
            <PhoneNumber defaultNumber={userNumber} />
          </div>
        </div>
      );
  }
}

export default OnBoard;
