import {
  GET_RISKIT,
  GET_RISKIT_SUCCESS,
  GET_RISKIT_ERROR,
  NO_QUESTION,
  PROVE_RISKIT,
  RISKIT_PROVEN,
  RISKIT_NOT_PROVEN,
  STORE_QUESTION,
  RESET
} from './actions';

const initialState = {
  loading: false,
  proven: null,
  error: '',
  attempts: 0,
  question: {}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_RISKIT:
      return { ...state, loading: false, error: '' };

    case GET_RISKIT_SUCCESS:
      return { ...state, loading: true };

    case GET_RISKIT_ERROR:
      return { ...state, loading: false };

    case NO_QUESTION:
      return { ...state, error: 'no-question' };

    case PROVE_RISKIT:
      return { ...state, attempts: state.attempts + 1 };

    case RISKIT_PROVEN:
      return { ...state, proven: true };

    case RISKIT_NOT_PROVEN:
      return { ...state, proven: false };

    case STORE_QUESTION:
      return { ...state, question: action.question, attempts: 0 };

    case RESET:
      return initialState;

    default:
      return state;
  }
}
