const setupOptions = options => {
  let optionsObj = {};

  for (let option in options) {
    if (
      ['optionA', 'optionB', 'optionC', 'optionD'].includes(option) &&
      options[option] !== ''
    ) {
      optionsObj[option] = options[option];
    }
  }

  return optionsObj;
};

export default setupOptions;
