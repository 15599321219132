import React from 'react';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

import { ReactComponent as Arrow } from '../../images/back-button.svg';

function QuestionOptions({ options, onClick }) {
  let optionsHTML = [];
  for (const key in options) {
    optionsHTML.push(
      <Option key={key}>
        <Button
          fluid
          className="option"
          onClick={e => onClick(options[key], e)}
        >
          <div className="animation-fill-white"></div>
          <div className="animation-fill-other"></div>
          <span>{options[key]}</span>
          <Arrow />
        </Button>
      </Option>
    );
  }
  return (
    <div className="question-options mt-3" id="QuestionOptions">
      {optionsHTML}
    </div>
  );
}

const Option = styled.div`
  margin-bottom: 13px;

  .ui.button {
    margin-bottom: 0px !important;
  }
`;

export default QuestionOptions;
