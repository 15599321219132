import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Popeye from './Popeye';
import { ReactComponent as BWLogo } from '../images/brainwave.svg';

const Cover = () => {
  const { cover } = useSelector(state => state.ui);
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (cover === false) {
      setShow(false);
    }
  }, [cover]);

  return (
    <Popeye show={show} class={['cover']}>
      <div className="container">
        <BWLogo />
      </div>
    </Popeye>
  );
};

export default Cover;
