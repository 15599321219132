import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import Router from '../components/Router';
import ToastMaster from '../components/ToastMaster';
import { showToasts } from '../modules/gamePlay/actions';
import UnlockModal from '../components/UnlockedModal';
import ErrorBoundary from '../shared/components/ErrorBoundary';

// import 'semantic-ui-less/definitions/globals/reset.less';
// import 'semantic-ui-less/definitions/globals/site.less';
import 'semantic-ui-less/definitions/elements/button.less';
// import 'semantic-ui-less/definitions/elements/icon.less';
import 'semantic-ui-less/definitions/elements/input.less';
import 'semantic-ui-less/definitions/elements/image.less';
import 'semantic-ui-less/definitions/elements/loader.less';
import 'semantic-ui-less/definitions/modules/dimmer.less';
import 'semantic-ui-less/definitions/modules/modal.less';
import 'semantic-ui-less/definitions/modules/checkbox.less';
import 'semantic-ui-less/definitions/modules/transition.less';
import 'semantic-ui-less/definitions/modules/dropdown.less';
import '../components/scss/main.scss';

import {
  wsConnect,
  updateManager,
  swUpdating,
  swUpdated,
  SW_UPDATE
} from '../modules/app/actions';
import Alert from '../components/Alert';
import Image from '../images/bg.png';

const App = ({ persistor }) => {
  const dispatch = useDispatch();
  const {
    info: { msisdn }
  } = useSelector(state => state.player);
  const socket = useSelector(state => state.app.ws.socket);
  const { toasts, inGame, unlocked } = useSelector(state => state.gamePlay);
  const [showAchievement, setShowAchievement] = useState(false);
  const serviceWorker = useSelector(state => state.app.sw);

  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorker.registration.waiting;
    console.log('%c%s', 'color:steelBlue', 'ready to update service worker');
    console.log(typeof serviceWorker.registration);
    console.log(serviceWorker.registration.waiting);
    console.table(serviceWorker.registration);

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

      registrationWaiting.addEventListener('statechange', e => {
        if (e.target.state === 'activated') {
          window.postMessage({ type: 'SERVICE_WORKER_UPDATED' });
          window.location.reload();
        }
      });
    }
  };

  const handlePostMessage = event => {
    if (event.data.type === 'SERVICE_WORKER_UPDATED') {
      dispatch(swUpdating());
    }
  };

  /**
   * Connect to socket
   */
  useEffect(() => {
    dispatch(wsConnect());
    window.addEventListener('message', handlePostMessage, false);
    console.log(typeof serviceWorker.registration);
    return () => {
      console.log('cleanup');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Start in game notifications
   */
  useEffect(() => {
    if (msisdn && Object.entries(socket).length > 0) {
      dispatch(updateManager());
    }
    // eslint-disable-next-line
  }, [msisdn, socket]);

  /**
   * Listen for Notifications
   */
  useEffect(() => {
    if (Object.entries(toasts).length > 0 && inGame === false) {
      Object.entries(toasts).map(message => {
        return toast(message[1]);
      });
      dispatch(showToasts());
    }
    // eslint-disable-next-line
  }, [toasts, inGame]);

  /**
   * Listen for Achievements
   */
  useEffect(() => {
    if (unlocked.length > 0) {
      setShowAchievement(true);
    } else {
      setShowAchievement(false);
    }
  }, [unlocked]);

  return (
    <PersistGate loading={null} persistor={persistor}>
      <ErrorBoundary>
        <BrowserRouter>
          <ToastMaster autoClose={4000} />
          <UnlockModal
            open={showAchievement}
            onClick={() => setShowAchievement(false)}
          />
          {serviceWorker.updated &&
            inGame === false &&
            (() => {
              toast('Brainwave app has been updated successfully.', {
                type: toast.TYPE.INFO
              });
              dispatch(swUpdated());
            })()}
          {serviceWorker.update && inGame === false && (
            <Alert
              text="Nobody likes bugs, so we've squashed quite a few in this release to make your experience better."
              buttonText="Update Now"
              type={SW_UPDATE}
              onClick={updateServiceWorker}
            />
          )}
          <Router />
          <img src={Image} className="bg" id="backgroundBg" alt="Brainwave" />
        </BrowserRouter>
      </ErrorBoundary>
    </PersistGate>
  );
};

export default App;
